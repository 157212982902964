import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { BGTableColumn } from './table-columns.model';
import { FormBuilder } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
  selector: 've-table',
  templateUrl: './table.component.html',
  styles: [],
})
export class TableComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() dataSource: any[] | undefined | null = [];
  @Input() columns: BGTableColumn[] = [];
  @Input() pagination = false;
  @ViewChild('paginator') paginator: MatPaginator | undefined;
  _dataSource = new MatTableDataSource<any>(this.dataSource || []);
  @ViewChildren(MatMenuTrigger) triggers: QueryList<MatMenuTrigger> | undefined;
  @Output() selectChange = new EventEmitter();
  position: { x: string; y: string } = { x: '0px', y: '0px' };
  displayedColumns: string[] = [];
  selectedColumn: BGTableColumn | any = {};
  filteredSource: any[] | undefined | null = [];
  selection: SelectionModel<any> = new SelectionModel(true);
  constructor(private cd: ChangeDetectorRef, private fb: FormBuilder) {
    return;
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    // if (simpleChanges.dataSource) {
    if (simpleChanges['dataSource']) {
      this._dataSource.data = this.dataSource || [];
    }
    // if (simpleChanges.columns) {
    if (simpleChanges['columns']) {
      this.displayedColumns = this.columns.map((col) => {
        if (col.templateFn) {
          col.template = col.templateFn();
        }
        if (col.headerTemplateFn) {
          col.headerTemplate = col.headerTemplateFn();
        }
        return col.key;
      });
    }
    // if (simpleChanges.pagination) {
    if (simpleChanges['pagination']) {
      this.togglePagination();
    }
    this.filteredSource = this.dataSource;
    return;
  }
  togglePagination() {
    if (this.paginator && this.pagination) {
      this._dataSource.paginator = this.paginator;
    } else {
      this._dataSource.paginator = null;
    }
  }
  ngAfterViewInit() {
    this.displayedColumns = this.columns.map((col) => {
      if (col.templateFn) {
        col.template = col.templateFn();
      }
      if (col.headerTemplateFn) {
        col.headerTemplate = col.headerTemplateFn();
      }
      return col.key;
    });
    this.togglePagination();
    this.cd.detectChanges();
  }
  ngOnInit(): void {
    return;
  }
  onPageChange(event: PageEvent) {
    console.log(event);
  }
  openMenu(event: MouseEvent, column: BGTableColumn, menuRef: MatMenu) {
    console.log(event, menuRef);
    event.preventDefault();
    this.position.x = event.clientX + 'px';
    this.position.y = event.clientY + 'px';
    const menu = this.triggers?.get(0);
    this.selectedColumn = column;
    menu?.openMenu();
  }

  onMenuClosed() {
    this.selectedColumn = undefined;
  }

  sort(isAccending: boolean) {
    if (isAccending) {
      this._dataSource.data.sort((a, b) => {
        const textA = a[this.selectedColumn.key].toUpperCase();
        const textB = b[this.selectedColumn.key].toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
    } else {
      this._dataSource.data.sort((a, b) => {
        const textA = a[this.selectedColumn.key].toUpperCase();
        const textB = b[this.selectedColumn.key].toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      });
      this._dataSource.data.reverse();
    }
    this._dataSource.data = [...this._dataSource.data];
  }

  search(searchTerm: string) {
    this._dataSource.filter = searchTerm;
    return;
  }

  resetFilter(inputRef: any) {
    this._dataSource.filter = '';
    this._dataSource.data = this.dataSource || [];
    inputRef.value = '';
  }

  showSelect() {
    if (this.displayedColumns.includes('_select')) {
      this.displayedColumns.splice(0, 1);
    } else {
      this.displayedColumns.unshift('_select');
    }
  }

  onSelectChange(event: MatCheckboxChange, row: any) {
    if (event.checked) {
      this.selection?.select(row);
    } else {
      this.selection?.deselect(row);
    }
    this.selectChange.emit(this.selection.selected);
  }
  selectAll(event: MatCheckboxChange) {
    if (event.checked) {
      this.selection.clear();
      this.selection.select(...(this.filteredSource as any[]));
    } else {
      this.selection.clear();
    }
  }
}
