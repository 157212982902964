// STATE AUTH STATE
import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { Auth, User } from '@vertice/data';
import { Session } from '@supabase/supabase-js';
import { from } from 'rxjs';
import { patch } from '@ngxs/store/operators';
import { GetAuthenticatedUser, UnsetUser, UserState, UserStateModel } from '@vertice/state';
// import { Auth } from '@vertice/data';


export class Authenticate {
    static readonly type = '[Auth] Authenticate';
    constructor(public payload: { session: Session }) { }
    
}

export class RefreshToken {
  static readonly type = '[Auth] Refresh Token';
}

export class SetToken {
  static readonly type = '[Auth] Set Token';
  constructor(public payload: { token: string; refreshToken: string }) {}
}

export class ClearToken {
  static readonly type = '[Auth] Clear Token';
}

export class Logout {
    static readonly type = '[Auth] Logout';
}

export class LogoutSuccess {
  static readonly type = '[Auth] Logout Success';
}

export interface AuthStateModel {
    auth: Auth | null;
    session: Session | null;
    loading: boolean;
}

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    auth: {
      token: null,
      refreshToken: null,
    },
    session: null,
    loading: false,
  },
})
@Injectable()
export class AuthState {
  constructor(private authService: AuthService, private store: Store) {}




  @Selector()
  static isAuthenticated(state: AuthStateModel): boolean {
    // return !!state.auth?.token;
    return !!state.auth?.token && !!state.session;

  }

  @Selector()
  static getToken(state: AuthStateModel): string | null {
    return state.auth?.token || null;
  }

  @Selector()
  static getRefreshToken(state: AuthStateModel): string | null {
    return state.auth?.refreshToken || null;
  }


  @Action(SetToken)
  setToken(ctx: StateContext<AuthStateModel>, action: SetToken) {
    const { token, refreshToken } = action.payload;
    ctx.patchState({
      auth: {
        token,
        refreshToken,
      },
    });
    this.authService.updateAuthState(true);
  }

  @Action(ClearToken)
  clearToken(ctx: StateContext<AuthStateModel>) {
    ctx.patchState({
      auth: {
        token: null,
        refreshToken: null,
      },
    });
  }

  @Action(Authenticate)
  authenticate(ctx: StateContext<AuthStateModel>, action: Authenticate) {
    console.log('***AUTH STATE:*** ACTION PAYLOAD:', action.payload);

    const { session } = action.payload;
    if (session?.access_token) {
      ctx.setState(
        patch({
          auth: {
            token: session.access_token,
            refreshToken: session.refresh_token || null,
          },
          session,
          loading: false,
        })
      );
      this.authService.updateAuthState(true);

      console.log(
        '***AUTH STATE:*** State after Authenticate:',
        ctx.getState()
      );
    } else {
      throw new Error('Invalid session object');
    }

    console.log('***AUTH STATE:*** Authenticate action:', action);

    // return this.store.dispatch(new GetAuthenticatedUser());

  }

  @Action(Logout)
  logout(ctx: StateContext<AuthStateModel>) {
    ctx.setState({
      auth: {
        token: null,
        refreshToken: null,
      },
      session: null,
      loading: false,
    });
    this.authService.updateAuthState(false);
    this.store.dispatch(new UnsetUser());
  }

  @Action(RefreshToken)
  refreshToken(ctx: StateContext<AuthStateModel>, action: RefreshToken) {
    return this.authService.refreshToken().pipe(
      tap((result) => {
        // Changed: Check if result is successful
        if (result.success && result.session) {
          ctx.setState(
            patch({
              auth: {
                token: result.session.access_token,
                refreshToken: result.session.refresh_token || null,
              },
              session: result.session,
              loading: false,
            })
          );
          this.authService.updateAuthState(true);
        } else {
          this.store.dispatch(new Logout());
        }
      }),
      catchError((error) => {
        console.error('Token refresh failed:', error);
        this.store.dispatch(new Logout());
        return throwError(() => new Error('Token refresh failed'));
      })
    );
  }


}

