<div
  class="flex flex-row items-center p-4 border border-gray-100 rounded cursor-pointer justify-items-start"
  [ngClass]="{'bg-gray-100': item.selected}" *ngIf="!!item">
  <div class="flex flex-col justify-center w-8">
    <mat-checkbox (click)="selectionChanged.emit()" [(ngModel)]="item.selected"
      [disabled]="item.isSelectionDisabled"></mat-checkbox>
  </div>
  <div class="flex items-center w-3/6 overflow-hidden overflow-ellipsis">
    <!-- <img src="https://material.angular.io/assets/img/examples/shiba1.jpg" alt="User" class="w-12 h-12 mr-4 align-middle rounded-full"> -->
    <div>
      <div class="font-bold">{{item.title}}</div>
      <div class="text-sm text-surface-medium">{{item.subTitle}}</div>
    </div>
  </div>
  <div class="items-center w-3/6 overflow-hidden text-sm text-gray-400 overflow-ellipsis">
    {{item.teams}}
  </div>
</div>
