// import { authenticatedGuard, unauthenticatedGuard } from './auth.guard';
import { authenticatedGuard, unauthenticatedGuard } from './auth.guard';

export const guards = [
  // { provide: 'authenticatedGuard', useValue: authenticatedGuard },
  { provide: 'authenticatedGuard', useValue: authenticatedGuard },
  { provide: 'unauthenticatedGuard', useValue: unauthenticatedGuard },
];

export * from './auth.guard';
