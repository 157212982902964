<button mat-icon-button mat-dialog-close>
  <mat-icon>cancel</mat-icon>
</button>
<div mat-dialog-title>{{ data.title }}<br>
  <div mat-dialog-subtitle>{{ data.subTitle }}</div>
</div>
<mat-dialog-content class="bg-white">
  <mat-form-field appearance="outline">
    <input matInput placeholder="Search">
    <mat-icon matPrefix>search</mat-icon>
  </mat-form-field>

  <ve-select-dialog-item class="mb-4 block" [item]="item" *ngFor="let item of data.items">
  </ve-select-dialog-item>

</mat-dialog-content>
<mat-dialog-actions>
  <button mat-stroked-button color="accent" (click)="close()">
    {{'common.cancel' | translate}}
  </button>
  <button mat-flat-button color="accent" (click)="submit()">{{data.title}}</button>
</mat-dialog-actions>
