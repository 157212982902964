<div
  class="flex flex-row items-center p-4 mb-4 border border-gray-100 rounded cursor-pointer justify-items-start"
  >
  <!-- *ngIf="item"> -->
  <div class="flex items-center w-3/6">
    <!-- <img src="https://material.angular.io/assets/img/examples/shiba1.jpg" alt="User"
      class="w-12 h-12 mr-4 align-middle rounded-full"> -->
    <div>
      <!-- <div class="w-3/6 font-bold">{{item.title}}</div>
      <div class="text-sm text-surface-medium">{{item.subTitle}}</div> -->
    </div>
  </div>
  <div class="items-center w-2/6 text-sm text-gray-400">
    <mat-form-field class="w-full m-p-0-input">
      <!-- <mat-select [(value)]="selectedRole" (selectionChange)="newRoleSelected($event)">
        <mat-option *ngFor="let role of roles" [value]="role.value">
          {{role.viewValue}}
        </mat-option>
      </mat-select> -->
    </mat-form-field>
  </div>
</div>
