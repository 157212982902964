import { CommonModule } from '@angular/common';
import { ListComponent } from './list/list.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TableComponent } from './table/table.component';
import { TranslateModule } from '@ngx-translate/core';
@NgModule({
  declarations: [TableComponent, ListComponent],
  imports: [
    CommonModule,
    MatTableModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    MatPaginatorModule,
    TranslateModule,
  ],
  exports: [TableComponent, ListComponent],
})
export class TableModule {}
