import {
  AbstractControl,
  FormControl,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatMenuItem, MatMenuTrigger } from '@angular/material/menu';
import { createMask } from '@ngneat/input-mask';
import { isNumber } from 'lodash';

export interface Time {
  h: string;
  m: string;
}

@Component({
  selector: 've-time-select',
  templateUrl: 'time-select.component.html',
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeSelectComponent implements OnInit, OnChanges {
  @Output() saveTime = new EventEmitter<Time>();
  @Input() time?: Time;
  @Input() readonly = false;
  @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger | undefined;
  @ViewChild('menuItem') menuItem: MatMenuItem | undefined;
  steps: Time[] = [];
  selectedTime = '';
  stepInMinutes = 30;
  start = 8;
  timeControl = new FormControl('', this.forbiddenTimeValidator());

  timeInputMask = createMask<string>({
    alias: 'datetime',
    inputFormat: 'HH:MM',
    // parser: (value: string) => {
    //   const valueGroups = value.split(' ');
    //   const values = valueGroups[0].split('/');
    //   const year = +values[2];
    //   const month = +values[0] - 1;
    //   const date = +values[1];
    //   const timeValues = valueGroups[1].split("'");

    //   const hour = timeValues[0].indexOf('H') !== -1 ? 0 : +timeValues[0];
    //   const minute = timeValues[1].indexOf('M') !== -1 ? 0 : +timeValues[1];
    //   return new Date(Date.UTC(year, month, date, hour, minute)).toISOString();
    // },
  });

  ngOnInit() {
    this.setSteps();
    this.setSelectedTime();
  }

  forbiddenTimeValidator(): ValidatorFn {
    return (timeControl: AbstractControl): ValidationErrors | null => {
      let forbidden = false;
      if (timeControl.value.length >= 2) {
        forbidden = +(timeControl.value[0] + timeControl.value[1]) > 24;
      }
      if (isNaN(+timeControl.value.replace(':', ''))) {
        forbidden = true;
      }
      return forbidden ? { forbiddenTime: { value: timeControl.value } } : null;
    };
  }

  get timeControlValue(): string {
    return this.timeControl.value ?? '';
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['time']) {
      this.setSelectedTime();
    }
  }

  menuOpened() {
    setTimeout(() => this.menuItem?.focus(), 100);
  }

  timeStepString(time: Time): string {
    return `${time?.h}:${time?.m}` ?? '';
  }

  isStepInFocus(step: Time): boolean {
    return this.timeControl.value === this.timeStepString(step);
  }

  setSelectedTime() {
    const hoursView = this.timeView(this.time?.h);
    const minutesView = this.timeView(this.time?.m);

    const time = this.steps.find(
      (time) => time.h === hoursView && time.m === minutesView
    );
    this.selectedTime = time ? `${time?.h}:${time?.m}` : '';
    this.timeControl.setValue(
      this.selectedTime
        ? this.selectedTime
        : this.time
        ? `${hoursView}:${minutesView}`
        : ''
    );
  }

  setInputControl(step: Time) {
    this.timeControl.setValue(this.timeStepString(step));
    this.setTime();
  }

  setSteps() {
    Array.from(Array((24 * 60) / this.stepInMinutes).keys()).forEach((val) => {
      const valInMinutes = val * this.stepInMinutes;
      const hours = this.getHours(valInMinutes);
      if (+hours >= this.start) {
        this.steps.push({
          h: this.getHours(valInMinutes),
          m: this.getMinutes(valInMinutes),
        });
      }
    });
  }

  getMinutes(valInMinutes: number): string {
    const minutes = valInMinutes - Math.floor(valInMinutes / 60) * 60;
    return this.timeView(minutes);
  }

  getHours(valInMinutes: number): string {
    const hours = Math.floor(valInMinutes / 60);
    return this.timeView(hours);
  }

  inputFieldOnTabPressed() {
    this.menuTrigger?.closeMenu();
  }

  timeView(time: number | string | undefined): string {
    return time?.toString().length === 1 ? '0' + time : time?.toString() ?? '';
  }

  timeChanged() {
    this.setTime();
  }

  setTime() {
    if (this.timeControlValue) {
      const h = this.timeControlValue[0] + this.timeControlValue[1];
      const m = this.timeControlValue[3] + this.timeControlValue[4];
      this.saveTime.emit({ h, m });
    }
  }

  onFocusOut() {
    const customInput = this.timeControlValue.replace(/\D/g, '');
    if (isNumber(+customInput) && !isNaN(+customInput)) {
      const customInputLength = customInput.length;
      if (customInputLength >= 4) {
        this.timeControl.setValue(
          `${customInput[0]}${customInput[1]}:${customInput[2]}${customInput[3]}`
        );
      }
      if (customInputLength === 1) {
        this.timeControl.setValue(`0${customInput[0]}:00`);
      }
      if (customInputLength === 2) {
        this.timeControl.setValue(`${customInput[0]}${customInput[1]}:00`);
      }
      if (customInputLength === 3) {
        this.timeControl.setValue(
          `${customInput[0]}${customInput[1]}:${customInput[2]}0`
        );
      }
    }
    this.timeChanged();
  }
}
