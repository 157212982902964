import { State, Action, StateContext, Selector } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { BroadcastMessages, StudentDetailsAndCourses } from '@vertice/data';
import { RxJsDebugService } from '../../../../../apps/vertice/src/app/shared/rxjs-debug.service';
import { of } from 'rxjs';
// WhatsappSenderState

export interface BroadcastDetails {
  id: string;
  name: string;
  selectedStudents: StudentDetailsAndCourses[];
  messages: BroadcastMessages[];
  autoReplyMessage?: string;
  department?: string;
  departmentId?: string;
  forwardToNumber?: string;
  isScheduling?: boolean;
  scheduleDate?: Date;
  scheduleTime?: string;
  canSend: boolean;
}

export interface WhatsappSenderStateModel {
  broadcasts: { [key: string]: BroadcastDetails };
  currentBroadcastId: string | null;
  currentStep: number;
}

export class UpdateBroadcastAutoReply {
  static readonly type = '[WhatsappSender] Update Broadcast Auto Reply';
  constructor(public payload: { id: string, autoReplyMessage: string, departmentId: string, forwardToNumber: string, }) { }
}

export class UpdateBroadcastCanSend {
  static readonly type = '[WhatsappSender] Update Broadcast Can Send';
  constructor(public payload: { id: string, canSend: boolean }) { }
}

export class ResetWhatsappSenderState {
  static readonly type = '[WhatsappSender] Reset State';
}
export class InitializeWizard {
  static readonly type = '[WhatsappSender] Initialize Wizard';
}

export class SetCurrentStep {
  static readonly type = '[WhatsappSender] Set Current Step';
  constructor(public payload: number) { }
}

export class UpdateIsSchedulingBroadcast {
  static readonly type = '[WhatsappSender] Update Is Scheduling Broadcast';
  constructor(public isScheduling: boolean) { }
}


export class SetBroadcastId {
  static readonly type = '[WhatsappSender] Set Broadcast ID';
  constructor(public payload: string) { }
}


export class UpdateSelectedStudents {
  static readonly type = '[WhatsappSender] Update Selected Students';
  constructor(public payload: { broadcastId: string, students: StudentDetailsAndCourses[] }) { }
}


export class SetCurrentBroadcast {
  static readonly type = '[WhatsappSender] Set Current Broadcast';
  constructor(public payload: string) { }
}

export class UpdateBroadcastDetails {
  static readonly type = '[WhatsappSender] Update Broadcast Details';
  constructor(public payload: { id: string, details: Partial<BroadcastDetails> }) { }
}

export class AddMessage {
  static readonly type = '[WhatsappSender] Add Message';
  constructor(public payload: { broadcastId: string, message: BroadcastMessages }) { }
}

export class UpdateMessage {
  static readonly type = '[WhatsappSender] Update Message';
  constructor(public payload: { broadcastId: string, message: BroadcastMessages }) { }
}

export class RemoveMessage {
  static readonly type = '[WhatsappSender] Remove Message';
  constructor(public payload: { broadcastId: string, messageId: string }) { }
}

@State<WhatsappSenderStateModel>({
  name: 'whatsappSender',
  defaults: {
    broadcasts: {},
    currentBroadcastId: null,
    currentStep: 1
  }
})
@Injectable()
export class WhatsappSenderState {
  constructor(private rxJsDebugService: RxJsDebugService) { }

  @Selector()
  static currentStep(state: WhatsappSenderStateModel): number {
    return state.currentStep;
  }

  @Selector()
  static currentBroadcast(state: WhatsappSenderStateModel): BroadcastDetails | null {
    return state.currentBroadcastId ? state.broadcasts[state.currentBroadcastId] : null;
  }

  @Selector()
  static currentBroadcastId(state: WhatsappSenderStateModel): string | null {
    return state.currentBroadcastId;
  }

  @Action(SetCurrentStep)
  setCurrentStep(ctx: StateContext<WhatsappSenderStateModel>, action: SetCurrentStep): void {
    ctx.patchState({
      currentStep: action.payload
    });
  }
  @Action(InitializeWizard)
  initializeWizard(ctx: StateContext<WhatsappSenderStateModel>): string {
    const newBroadcastId = crypto.randomUUID();
    const state = ctx.getState();
    ctx.setState({
      ...state,
      broadcasts: {
        ...state.broadcasts,
        [newBroadcastId]: {
          id: newBroadcastId,
          name: '',
          selectedStudents: [],
          messages: [],
          autoReplyMessage: '',
          department: '',
          canSend: false,
        }
      },
      currentBroadcastId: newBroadcastId,
      currentStep: 1
    });
    return newBroadcastId;
    // return this.rxJsDebugService.debug(of(newBroadcastId), 'InitializeWizard');

  }

  @Action(SetCurrentBroadcast)
  setCurrentBroadcast(ctx: StateContext<WhatsappSenderStateModel>, action: SetCurrentBroadcast): void {
    ctx.patchState({
      currentBroadcastId: action.payload
    });
  }

  @Action(UpdateBroadcastDetails)
  updateBroadcastDetails(ctx: StateContext<WhatsappSenderStateModel>, action: UpdateBroadcastDetails): void {
    const state = ctx.getState();
    const { id, details } = action.payload;
    ctx.patchState({
      broadcasts: {
        ...state.broadcasts,
        [id]: {
          ...state.broadcasts[id],
          ...details
        }
      }
    });
  }

  @Action(AddMessage)
  addMessage(ctx: StateContext<WhatsappSenderStateModel>, action: AddMessage): void {
    const state = ctx.getState();
    const { broadcastId, message } = action.payload;
    ctx.patchState({
      broadcasts: {
        ...state.broadcasts,
        [broadcastId]: {
          ...state.broadcasts[broadcastId],
          messages: [...state.broadcasts[broadcastId].messages, message]
        }
      }
    });
  }

  @Action(UpdateMessage)
  updateMessage(ctx: StateContext<WhatsappSenderStateModel>, action: UpdateMessage): void {
    const state = ctx.getState();
    const { broadcastId, message } = action.payload;
    ctx.patchState({
      broadcasts: {
        ...state.broadcasts,
        [broadcastId]: {
          ...state.broadcasts[broadcastId],
          messages: state.broadcasts[broadcastId].messages.map(msg =>
            msg.id === message.id ? message : msg
          )
        }
      }
    });
  }

  @Action(RemoveMessage)
  removeMessage(ctx: StateContext<WhatsappSenderStateModel>, action: RemoveMessage): void {
    const state = ctx.getState();
    const { broadcastId, messageId } = action.payload;
    ctx.patchState({
      broadcasts: {
        ...state.broadcasts,
        [broadcastId]: {
          ...state.broadcasts[broadcastId],
          messages: state.broadcasts[broadcastId].messages.filter(msg => msg.id !== messageId)
        }
      }
    });
  }
  @Action(UpdateSelectedStudents)
  updateSelectedStudents(ctx: StateContext<WhatsappSenderStateModel>, action: UpdateSelectedStudents): void {
    const state = ctx.getState();
    const { broadcastId, students } = action.payload;
    if (state.broadcasts[broadcastId]) {
      ctx.setState({
        ...state,
        broadcasts: {
          ...state.broadcasts,
          [broadcastId]: {
            ...state.broadcasts[broadcastId],
            selectedStudents: students
          }
        }
      });
    } else {
      console.error('***WHATSAPP-SENDER-STATE/updateSelectedStudents*** Broadcast not found:', broadcastId);
    }
  }
  @Action(ResetWhatsappSenderState)
  resetState(ctx: StateContext<WhatsappSenderStateModel>): void {
    ctx.setState({
      broadcasts: {},
      currentBroadcastId: null,
      currentStep: 1
    });
  }

  // Auto reply / can send validation

  @Action(UpdateBroadcastAutoReply)
  updateBroadcastAutoReply(ctx: StateContext<WhatsappSenderStateModel>, action: UpdateBroadcastAutoReply) {
    const state = ctx.getState();
    const { id, autoReplyMessage, departmentId, forwardToNumber } = action.payload;
    const broadcast = state.broadcasts[id];

    if (broadcast) {
      ctx.patchState({
        broadcasts: {
          ...state.broadcasts,
          [id]: {
            ...broadcast,
            autoReplyMessage,
            departmentId,
            forwardToNumber
          }
        }
      });
    }
  }


  @Action(UpdateBroadcastCanSend)
  updateBroadcastCanSend(ctx: StateContext<WhatsappSenderStateModel>, action: UpdateBroadcastCanSend) {
    const state = ctx.getState();
    const broadcast = state.broadcasts[action.payload.id];
    if (broadcast) {
      ctx.patchState({
        broadcasts: {
          ...state.broadcasts,
          [action.payload.id]: {
            ...broadcast,
            canSend: action.payload.canSend
          }
        }
      });
      console.log('***STATE*** UpdateBroadcastCanSend: Updated canSend to', action.payload.canSend);
    } else {
      console.log('***STATE*** UpdateBroadcastCanSend: Broadcast not found');
    }
  }
}


// @Action(UpdateBroadcastAutoReply)
// updateBroadcastAutoReply(ctx: StateContext<WhatsappSenderStateModel>, action: UpdateBroadcastAutoReply) {
//   const state = ctx.getState();
//   const broadcast = state.broadcasts[action.payload.id];
//   if (broadcast) {
//     ctx.patchState({
//       broadcasts: {
//         ...state.broadcasts,
//         [action.payload.id]: {
//           ...broadcast,
//           autoReplyMessage: action.payload.autoReplyMessage,
//           department: action.payload.departmentId,

//         }
//       }
//     });
//   }
// }