<div class="rounded bg-gray-200 flex flex-col items-center px-6 py-5 ">
  <div class="max-w-xs">
    <div class="mb-4 flex justify-center">
      <img src="/assets/empty-states/{{ image }}" alt="Empty State">
    </div>
    <h4 *ngIf="title" class="text-center">{{ title }}</h4>
    <p *ngIf="subTitle" class="text-center">{{ subTitle }}</p>
    <div class="mt-4 flex justify-center">
      <ng-content></ng-content>
    </div>

  </div>
</div>
