<!-- <mat-form-field class="w-20 m-p-0-input" appearance="outline"> -->
<div class="flex">
  <input [formControl]='timeControl' [inputMask]="timeInputMask"
    class="inline-block !w-16 outline-none focus:outline-none" type="text" matInput
    (keydown.Tab)="inputFieldOnTabPressed()" (focusout)="onFocusOut()" placeholder="HH:MM" [readonly]="readonly">
  <div class="-ml-2" *ngIf="!readonly">
    <mat-icon class="inline-block text-sm text-gray-400" #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu"
      (menuOpened)="menuOpened()">
      schedule
    </mat-icon>
  </div>
</div>
<!-- <mat-error *ngIf="timeControl.errors?.['forbiddenTime']">
    Invalid time
  </mat-error>
</mat-form-field> -->

<mat-menu #menu="matMenu" class="time-select">
  <ng-container *ngFor="let step of steps">
    <button *ngIf="isStepInFocus(step)" mat-menu-item #menuItem (click)="setInputControl(step)">
      <span>{{step.h}}:{{step.m}}</span>
    </button>
    <button *ngIf="!isStepInFocus(step)" mat-menu-item (click)="setInputControl(step)">
      <span>{{step.h}}:{{step.m}}</span>
    </button>
  </ng-container>
</mat-menu>