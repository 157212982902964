import { CellInputNumberComponent } from './cell-input-number/cell-input-number.component';
import { CellInputTextComponent } from './cell-input-text/cell-input-text.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [CellInputTextComponent, CellInputNumberComponent],
  imports: [CommonModule],
  exports: [CellInputTextComponent, CellInputNumberComponent],
})
export class CellInputsModule {}
