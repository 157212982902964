<!-- <section class="container">
  <header class="header">
    <ol>
      <div class="flex items-center gap-1">
        <ng-container *ngFor="let step of steps; let i = index;let last=last">

          <div (click)='!disableNavigation&&selectedIndex=i' class="!m-0 mat-body-2 cursor-pointer"
            [class.text-surface-disabled]="selectedIndex !== i"
            [class.text-secondary-500]=" selectedIndex === i"
            [ngClass]="{'text-secondary-500': selectedIndex === i}">{{i+1}}. {{step.label}}

          </div>
          <div class="flex items-center w-10 h-10 align-middle " *ngIf="!last">
            <mat-icon class="items-center justify-center text-xl leading-3 text-surface-medium"
              style="display: flex !important;">chevron_right</mat-icon>
          </div>

        </ng-container>

      </div>
    </ol>
  </header>

  <div *ngIf='!hidebody' class="p-2">
    <ng-container [ngTemplateOutlet]="selected?.content!"></ng-container>
  </div>
</section> -->
          <!-- class="!m-0 flex-1 cursor-pointer transition-colors duration-300 ease-in-out" -->

<!-- New -->
<section class="container">
  <header class="header">
    <ol class="flex justify-between items-center px-4 py-2">
      <ng-container *ngFor="let step of steps; let i = index; let last = last">
        <li (click)="!disableNavigation && selectedIndex = i"
          class="!m-0 flex-1 transition-colors duration-300 ease-in-out"
          [class.text-surface-disabled]="selectedIndex !== i" [class.text-secondary-500]="selectedIndex === i"
          [ngClass]="{'text-secondary-500': selectedIndex === i}">
          <div class="flex items-center justify-center">

            <div [ngClass]="{'bg-secondary-500 text-white': selectedIndex === i}"
              class="flex items-center justify-center w-8 h-8 rounded-full bg-gray-200 text-gray-600 font-bold mb-2 md:mb-0">
              {{i + 1}}
            </div>
            <!-- Lines between -->
            <!-- <div class="flex-1 h-1 bg-gray-200 ml-2" *ngIf="i !== steps.length - 1">
            </div> -->
          </div>

        </li>
      </ng-container>
    </ol>
  </header>
  <div *ngIf="!hidebody" class="p-4">
    <ng-container [ngTemplateOutlet]="selected?.content!"></ng-container>
  </div>
</section>
