import { User, UserProgress } from '@vertice/data';
import { Session } from '@supabase/supabase-js';
// users.actions.ts

export class SetUser {
  static readonly type = '[User] Set';
  constructor(
    public payload: {
      user: User;
      session?: Session | null;
    }
  ) {}
}

export class UnsetUser {
  static readonly type = '[User] Unset';
}

export class GetAuthenticatedUser {
  static readonly type = '[Auth] Get Authenticated User';
}

