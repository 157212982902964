import { SupabaseService } from 'apps/vertice/src/app/shared/services/supabase.service';
import { User } from '@vertice/data';
// import { User } from '@supabase/supabase-js';
import { Injectable } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { EMPTY, Observable, catchError, tap, filter, from, map, of, switchMap, take, throwError } from 'rxjs';
import {
  AddToStoreAction,
  UpdateStoreAction,
  RemoveFromStoreAction,
  UpdateInStoreAction,
  UserState,
  GetAuthenticatedUser,
  AuthService,
  UsersService,
} from '@vertice/state';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class BaseCrudQuery<T> {
  constructor(public store: Store, 
    public supabase: SupabaseService, 
    public authService: AuthService, 
    public router: Router,
    public userService: UsersService,

) {}

  // @Select(UserState.getAuthenticatedUser) currentUser$!: Observable<User | null>;

  // protected getAuthenticatedUser(): Observable<User | null> {
  //   console.log('Getting authenticated user');
  //   return this.currentUser$.pipe(
  //     take(1),
  //     switchMap(user => {
  //       if (user) {
  //         console.log('User found:', user);
  //         return of(user);
  //       } else {
  //         console.log('No user found, attempting to refresh token');
  //         return this.authService.refreshToken().pipe(
  //           switchMap(result => {
  //             if (result.success && result.session?.user) {
  //               console.log('Token refreshed successfully, user:', result.session.user);
  //               return of(result.session.user);
  //             }
  //             console.warn('Token refresh failed, redirecting to signin');
  //             this.router.navigate(['/signin']);
  //             return EMPTY;
  //           })
  //         );
  //       }
  //     }),
  //     catchError(error => {
  //       console.error('Error getting authenticated user:', error);
  //       this.router.navigate(['/signin']);
  //       return EMPTY;
  //     })
  //   );
  // }

  // protected getAuthenticatedUser(): Observable<User | null> {
  //   // console.log('***BASE CRUD QUERY*** Getting authenticated user');
  //   return this.currentUser$.pipe(
  //     take(1),
  //     switchMap(user => {
  //       if (user) {
  //         // console.log('***BASE CRUD QUERY*** User found:', user);
  //         return of(user);
  //       } else {
  //         // console.log('***BASE CRUD QUERY*** No user found, attempting to refresh token');
  //         return this.authService.refreshToken().pipe(
  //           switchMap(result => {
  //             if (result.success && result.session?.user) {
  //               // console.log('***BASE CRUD QUERY*** Token refreshed successfully, user:', result.session.user);
  //               return of(result.session.user);
  //             }
  //             // console.warn('***BASE CRUD QUERY*** Token refresh failed, redirecting to signin');
  //             this.router.navigate(['/signin']);
  //             return EMPTY;
  //           })
  //         );
  //       }
  //     }),
  //     catchError(error => {
  //       // console.error('***BASE CRUD QUERY*** Error getting authenticated user:', error);
  //       this.router.navigate(['/signin']);
  //       return EMPTY;
  //     })
  //   );
  // }

  getAll<T>(
    table: string,
    filter?: string,
    order?: string,
    autoUser: boolean = true
  ): Observable<T[]> {
    // console.log(`***BASE CRUD QUERY*** Getting all from table: ${table}`);
    return this.userService.getUser().pipe(
      // tap(user => console.log('***BASE CRUD QUERY*** User:', user)),

      switchMap(user => {
        if (!user) {
          // console.warn('***BASE CRUD QUERY*** No authenticated user, returning EMPTY');
          return EMPTY;
        }
        let query = this.supabase.from(table).select('*');

        if (autoUser && user.id) {
          // console.log(`***BASE CRUD QUERY*** Adding user filter: user_id = ${user.id}`);
          query = query.eq('user_id', user.id);
        }

        if (filter) {
          // console.log(`***BASE CRUD QUERY*** Adding custom filter: ${filter}`);
          const [column, operator, ...value] = filter.split('.');
          const filterValue = value.join('.');
          query = query.filter(column, operator, filterValue);
        }

        if (order) {
          // console.log(`***BASE CRUD QUERY*** Adding order: ${order}`);
          query = query.order(order, { ascending: true });
        }

        return from(query);
      }),
      map(response => {
        if (response.error) {
          // console.error('***BASE CRUD QUERY*** Supabase error:', response.error);
          throw response.error;
        }
        // console.log(`***BASE CRUD QUERY*** Retrieved ${response.data.length} records`);
        return response.data as T[];
      }),
      catchError(error => {
        console.error('***BASE CRUD QUERY*** Error in getAll:', error);
        return throwError(() => new Error(error.message));
      })
    );
  }

  getOne<T>(table: string, filter?: string, autoUser: boolean = true): Observable<T> {
    return this.userService.getUser().pipe(
      switchMap(user => {
        if (!user) {
          return EMPTY;
        }
        let query = this.supabase.from(table).select('*');
        if (autoUser && user) {
          query = query.eq('user_id', user.id);
        }
        // let query = this.supabase.from(table).select('*').eq('user_id', user.id);
        if (filter) {
          const [column, operator, value] = filter.split('.');
          query = query.filter(column, operator, value);
        }
        return from(query.single()).pipe(
          catchError((error) => throwError(() => new Error(error.message))),
          map((response) => {
            if (response.error) throw response.error;
            if (!response.data) throw new Error('No data found');
            const data: T = response.data as T;
            this.store.dispatch(new UpdateStoreAction(data));
            return data;
          })
        );
      })
    );
  }

  create<T>(table: string, data: T, includeUserId: boolean = true): Observable<T> {
    return this.userService.getUser().pipe(
      switchMap(user => {
        if (!user) {
          return EMPTY; 
        }
        const dataToInsert = includeUserId ? { ...data, user_id: user.id } : data;
        return from(this.supabase.from(table).insert([dataToInsert]).select()).pipe(
          catchError((error) => throwError(() => new Error(error.message))),
          map((response) => {
            if (response.error) {
              throw response.error;
            } else if (response.data) {
              const item = response.data[0] as T;
              this.store.dispatch(new AddToStoreAction(item));
              return item;
            } else {
              throw new Error('No data returned from insert');
            }
          })
        );
      })
    );
  }

  update<T>(
    table: string,
    data: Partial<T>,
    filter?: string
  ): Observable<T[]> {
    return this.userService.getUser().pipe(
      switchMap(user => {
        if (!user) {
          return EMPTY;
        }
        let query = this.supabase.from(table).update(data);

        if (filter) {
          const [column, operator, value] = filter.split('.');
          query = query.filter(column, operator, value);
        }

        return from(query).pipe(
          switchMap((response) => {
            if (response.error) {
              throw response.error;
            }
            if (response.count === 0) {
              console.log('No rows updated');
              return of([]);
            }

            let selectQuery = this.supabase.from(table).select('*');

            if (filter) {
              const [column, operator, value] = filter.split('.');
              selectQuery = selectQuery.filter(column, operator, value);
            }

            return from(selectQuery);
          }),
          catchError((error) => throwError(() => new Error(error.message))),
          map((response) => {
            if ('error' in response && response.error) throw response.error;
            let items: T[] = [];
            if ('data' in response && response.data) {
              items = response.data as T[];
              this.store.dispatch(
                new UpdateInStoreAction({ id: user.id, newValues: items[0] })
              );
            }
            return items;
          })
        );
      })
    );
  }

  upsert<T>(table: string, data: Partial<T>): Observable<T[]> {
    return this.userService.getUser().pipe(
      switchMap(user => {
        if (!user) {
          return EMPTY;
        }
        const dataWithUserId = {
          ...data,
          user_id: user.id,
        };

        const query = this.supabase.from(table).upsert(dataWithUserId);

        return from(query).pipe(
          switchMap(() => {
            const selectQuery = this.supabase
              .from(table)
              .select('*')
              .eq('user_id', user.id);
            return from(selectQuery);
          }),
          catchError((error) => throwError(() => new Error(error.message))),
          map((response) => {
            if (response.error) throw response.error;
            let items: T[] = [];
            if (response.data) {
              items = response.data as T[];
              this.store.dispatch(
                new UpdateInStoreAction({ id: user.id, newValues: items[0] })
              );
            }
            return items;
          })
        );
      })
    );
  }

  delete(table: string, id: string): Observable<void> {
    return this.userService.getUser().pipe(
      switchMap((user) => {
        if (!user) {
          return EMPTY;
        }
        return from(this.supabase.from(table).delete().eq('id', id)).pipe(
          catchError((error) => throwError(() => new Error(error.message))),
          map((response) => {
            if (response.error) throw response.error;
            this.store.dispatch(new RemoveFromStoreAction(id));
          })
        );
      })
    );
  }




  // v1 without auth user
  // getAll<T>(
  //   table: string,
  //   user?: User,
  //   filter?: string,
  //   order?: string
  // ): Observable<T[]> {
  //   let query = this.supabase.from(table).select('*');

  //   if (user) {
  //     query = query.eq('user_id', user.id);
  //   }
  //   // let query = this.supabase.from(table).select('*').eq('user_id', user.id);
  //   // console.log(`*GET ALL* Initial query: ${query.toString()}`);

  //   if (filter) {
  //     const [column, operator, ...value] = filter.split('.');
  //     const filterValue = value.join('.');
  //     // console.log(`*GET ALL* Filter parts: ${column}, ${operator}, ${filterValue}`);
  //     // console.log(`*GET ALL* full Filter: ${filter}`);
  //     query = query.filter(column, operator, filterValue);
  //   }

  //   if (order) {
  //     query = query.order(order, { ascending: true });
  //   }

  //   // console.log(`*GET ALL* Final query: ${query.toString()}`);

  //   return from(query).pipe(
  //     catchError((error) => {
  //       // console.error('*GET ALL*  Error fetching data:', error);
  //       return throwError(() => new Error(error.message));
  //     }),
  //     map((response) => {
  //       if (response.error) {
  //         // console.error('*GET ALL*  Supabase query error:', response.error);
  //         throw response.error;
  //       }
  //       const data: T[] = response.data as T[];
  //       // console.log('*GET ALL*  Fetched data:', data);
  //       this.store.dispatch(new UpdateStoreAction(data));
  //       return data;
  //     })
  //   );
  // }

  // getOne<T>(table: string, user: User, filter?: string): Observable<T> {
  //   let query = this.supabase.from(table).select('*').eq('user_id', user.id);
  //   if (filter) {
  //     const [column, operator, value] = filter.split('.');
  //     query = query.filter(column, operator, value);
  //   }
  //   return from(query.single()).pipe(
  //     catchError((error) => throwError(() => new Error(error.message))),
  //     map((response) => {
  //       if (response.error) throw response.error;
  //       if (!response.data) throw new Error('No data found');
  //       const data: T = response.data as T;
  //       this.store.dispatch(new UpdateStoreAction(data));
  //       return data;
  //     })
  //   );
  // }

  // create<T>(table: string, user: User, data: T, includeUserId: boolean = true): Observable<T> {
  //   const dataToInsert = includeUserId ? { ...data, user_id: user.id } : data;
  //   return from(this.supabase.from(table).insert([dataToInsert]).select()).pipe(
  //     catchError((error) => throwError(() => new Error(error.message))),
  //     map((response) => {
  //       if (response.error) {
  //         throw response.error;
  //       } else if (response.data) {
  //         const item = response.data[0] as T;
  //         this.store.dispatch(new AddToStoreAction(item));
  //         return item;
  //       } else {
  //         throw new Error('No data returned from insert');
  //       }
  //     })
  //   );
  // }




  // update<T>(
  //   table: string,
  //   user: User,
  //   data: Partial<T>,
  //   filter?: string
  // ): Observable<T[]> {
  //   let query = this.supabase.from(table).update(data);

  //   if (filter) {
  //     const [column, operator, value] = filter.split('.');
  //     query = query.filter(column, operator, value);
  //   }

  //   console.log('UPDATE QUERY:', query.toString());

  //   return from(query).pipe(
  //     switchMap((response) => {
  //       if (response.error) {
  //         throw response.error;
  //       }
  //       if (response.count === 0) {
  //         console.log('No rows updated');
  //         return of([]);
  //       }
        
  //       let selectQuery = this.supabase.from(table).select('*');

  //       if (filter) {
  //         const [column, operator, value] = filter.split('.');
  //         selectQuery = selectQuery.filter(column, operator, value);
  //       }

  //       return from(selectQuery);
  //     }),
  //     catchError((error) => throwError(() => new Error(error.message))),
  //     map((response) => {
  //       console.log('Update response:', response);
  //       if ('error' in response && response.error) throw response.error;
  //       let items: T[] = [];
  //       if ('data' in response && response.data) {
  //         items = response.data as T[];
  //         this.store.dispatch(
  //           new UpdateInStoreAction({ id: user.id, newValues: items[0] })
  //         );
  //       }
  //       return items;
  //     })
  //   );
  // }

  // upsert<T>(table: string, user: User, data: Partial<T>): Observable<T[]> {
  //   const dataWithUserId = {
  //     ...data,
  //     user_id: user.id,
  //   };

  //   const query = this.supabase.from(table).upsert(dataWithUserId);

  //   console.log('UPSERT:', { query });

  //   return from(query).pipe(
  //     switchMap(() => {
  //       const selectQuery = this.supabase
  //         .from(table)
  //         .select('*')
  //         .eq('user_id', user.id);
  //       return from(selectQuery);
  //     }),
  //     catchError((error) => throwError(() => new Error(error.message))),
  //     map((response) => {
  //       console.log('Upsert response:', response);
  //       if (response.error) throw response.error;
  //       let items: T[] = [];
  //       if (response.data) {
  //         items = response.data as T[];
  //         this.store.dispatch(
  //           new UpdateInStoreAction({ id: user.id, newValues: items[0] })
  //         );
  //       }
  //       return items;
  //     })
  //   );
  // }

  // delete(table: string, id: string): Observable<void> {
  //   return from(this.supabase.from(table).delete().eq('id', id)).pipe(
  //     catchError((error) => throwError(() => new Error(error.message))),
  //     map((response) => {
  //       if (response.error) throw response.error;
  //       this.store.dispatch(new RemoveFromStoreAction(id));
  //     })
  //   );
  // }


}
