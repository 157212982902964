import { Component, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ClassicEditor, Font, Bold, Essentials, Italic, Mention, Paragraph, Undo, List } from 'ckeditor5';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular/ckeditor.component';

@Component({
  selector: 've-rich-text-editor',
  templateUrl: './rich-text-editor.component.html',
  styleUrls: ['./rich-text-editor.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class RichTextEditorComponent {
  @Input() content: string = '';
  @Output() contentChange = new EventEmitter<string>();

  public Editor = ClassicEditor;
  public config = {
    toolbar: {
      items: [
        'undo', 'redo',
        '|',
        'bold', 'italic', 'blockQuote',
        '|',
        'bulletedList',
      ]
    },
    placeholder: 'Escreva uma mensagem...',
    heading: {
      options: [
        { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
        { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
        { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' }
      ]
    },
    licenseKey: 'JKJOAW583.ZXQ080JPO182',
    fontFamily: {
      options: [
        'default',
        'Ubuntu, Arial, sans-serif',
        'Ubuntu Mono, Courier New, Courier, monospace'
      ]
    },
    // language: 'pt'


    // plugins: [
    //   Bold, Essentials, Italic, Mention, Paragraph, Undo, List
    // ],
  };
  onEditorChange({ editor }: ChangeEvent) {
    const data = editor.getData();
    this.content = data;
    console.log(data);

    this.contentChange.emit(data);
  }
}