import {
  ChangeDetectionStrategy,
  Component,
  ContentChildren,
  Input,
  QueryList,
} from '@angular/core';
import { DialogStepperItemComponent } from '../dialog-stepper-item/dialog-stepper-item.component';

@Component({
  selector: 've-dialog-stepper',
  templateUrl: './dialog-stepper.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogStepperComponent {
  @Input()
  public current = 1;

  @Input()
  public startIndex = 1;

  @ContentChildren(DialogStepperItemComponent)
  public items!: QueryList<DialogStepperItemComponent>;
}
