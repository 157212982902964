import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RichTextEditorComponent } from './components/rich-text-editor.component';
import { MatTooltipModule } from "@angular/material/tooltip";
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { FormsModule } from '@angular/forms';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

@NgModule({
  declarations: [RichTextEditorComponent],
  imports: [
    CommonModule,
    MatTooltipModule,
    EditorModule,
    FormsModule,
    CKEditorModule,

  ],
  exports: [RichTextEditorComponent],
  providers: [
    { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' }
  ]
})

export class RichTextEditorModule {}
