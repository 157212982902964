<div class="flex items-center gap-2 ml-1">
  <ng-container *ngFor="let item of items; let i = index">
    <ve-dialog-stepper-item [position]="i + startIndex" [text]="item.text" [current]="current">
      <ng-template>
        <ng-container *ngTemplateOutlet="item.templateRef"></ng-container>
      </ng-template>
    </ve-dialog-stepper-item>
    <div class="flex items-center justify-center h-10 w-10" *ngIf="i + 1 < items.length">
      <mat-icon class="text-surface-medium text-xl leading-3 justify-center items-center"
        style="display: flex !important;">chevron_right</mat-icon>
    </div>
  </ng-container>
</div>
