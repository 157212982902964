
  <div class="mt-2 flex flex-row justify-end" *ngIf='createLabel'>
    <button  (click)='createClick.emit()' mat-button color='accent'>
      <!-- <mat-icon>add_circle_outline</mat-icon> {{createLabel}} -->
    </button>
  </div>


<!-- <ve-select-dialog-item (selectionChanged)="selectionChanged()" class="block" [item]="item" *ngFor="let item of filteredItems | bgSelectSearch: search.value: 'title':'subTitle'">
</ve-select-dialog-item> -->
