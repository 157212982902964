import { Component, Input } from '@angular/core';

@Component({
  selector: 've-page-empty-state',
  templateUrl: './page-empty-state.component.html',
  styles: [],
})
export class PageEmptyStateComponent {
  @Input() title: string | undefined;
  @Input() subTitle: string | undefined;
  @Input() image: string | undefined;
}
