<div class="relative">
  <mat-form-field class="relative " [ngClass]="{'no-optional': noOptional}">
    <mat-label>{{name || '' | translate}}</mat-label>
    <input matInput (focus)="select.focus()">
    <div class="absolute flex -left-1 top-4">
      <input class="z-10 !w-24 outline-none focus:outline-none" type="text" [inputMask]="dateInputMask"
        [(ngModel)]="date" placeholder="dd/mm/yyyy" matInput (blur)="onChanged()" #select>

      <!-- <ngx-material-timepicker-toggle *ngIf="active"
    class="absolute top-0 right-0 block text-gray-500" [for]="timePicker">
    <mat-icon class="text-gray-500" ngxMaterialTimepickerToggleIcon>
      schedule
    </mat-icon>
  </ngx-material-timepicker-toggle> -->
      <input style="width:1px;visibility:hidden;" class="" type="text" [formControl]="timepickerDateControl"
        [matDatepicker]="picker">
      <mat-datepicker #picker class=" -m-t-8"></mat-datepicker>

      <div class="">
        <mat-icon class="inline-block text-sm text-gray-400 " (click)="openDatePicker(picker)">
          calendar_today
        </mat-icon>
      </div>

      <ve-time-select class="inline-block" [time]="time" (saveTime)="timeSelected($event)">
      </ve-time-select>



    </div>

    <!-- <button mat-raised-button (click)="picker.open()">Open</button> -->

    <input [(ngModel)]="time" class="hidden">
  </mat-form-field>

</div>