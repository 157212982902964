import { CommonModule } from '@angular/common';
import { DialogService } from './dialog.service';
import { DialogStepperComponent } from './dialog-stepper/dialog-stepper/dialog-stepper.component';
import { DialogStepperItemComponent } from './dialog-stepper/dialog-stepper-item/dialog-stepper-item.component';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { NgModule } from '@angular/core';
import { SelectDialogComponent } from './select-dialog/select-dialog.component';
import { SelectDialogItemComponent } from './select-dialog-item/select-dialog-item.component';
import { SelectItemRoleDialogComponent } from './select-item-role-dialog/select-item-role-dialog.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    SelectDialogComponent,
    SelectDialogItemComponent,
    DialogStepperComponent,
    DialogStepperItemComponent,
    SelectItemRoleDialogComponent,
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    FormsModule,
    MatCardModule,
    MatSelectModule,
    TranslateModule,
  ],
  providers: [DialogService],
  exports: [
    SelectDialogComponent,
    SelectDialogItemComponent,
    DialogStepperComponent,
    DialogStepperItemComponent,
    SelectItemRoleDialogComponent,
  ],
})
export class DialogModule {}

export * from './select-dialog/select-dialog.component';
