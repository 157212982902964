import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  TemplateRef,
} from '@angular/core';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 've-list',
  templateUrl: './list.component.html',
  styles: [],
})
export class ListComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() dataSource: any[] | undefined | null = [];
  @Input() template: TemplateRef<any> | undefined;

  constructor(private cd: ChangeDetectorRef) {
    return;
  }

  ngOnChanges() {
    return;
  }

  ngAfterViewInit() {
    this.cd.detectChanges();
  }
  ngOnInit(): void {
    return;
  }

  onPageChange(event: PageEvent) {
  }
}
