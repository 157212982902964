import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from "@angular/material/tooltip";
import { FileUploadComponent } from './components/file-upload.component';

@NgModule({
  declarations: [FileUploadComponent],
  imports: [
    CommonModule,
    MatTooltipModule,
  ],
  exports: [FileUploadComponent]
})

export class FileUploadModule {}
