import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store, Select } from '@ngxs/store';
import { Observable, combineLatest, of } from 'rxjs';
import { map, tap, filter, take, switchMap, catchError } from 'rxjs/operators';
import { GoToLogin } from '../router/router.actions';
import { Injectable, inject } from '@angular/core';
import { AuthService, AuthState,  RefreshToken,  UserState } from '@vertice/state';
import { Navigate } from '@ngxs/router-plugin';


// Vanilla auth guard
// v1
// export const authenticatedGuard: CanActivateFn = (
//   route: ActivatedRouteSnapshot,
//   state: RouterStateSnapshot
// ): Observable<boolean | UrlTree> => {
//   const authService = inject(AuthService);
//   const router = inject(Router);

//   return authService.checkAuthState().pipe(
//     map(isAuthenticated => {
//       if (isAuthenticated) {
//         return true;
//       }
//       return router.createUrlTree(['/signin'], {
//         queryParams: { redirect: state.url },
//       });
//     })
//   );
// };

// v2

export const authenticatedGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<boolean | UrlTree> => {
  const authService = inject(AuthService);
  const router = inject(Router);
  const store = inject(Store);

  return authService.checkAuthState().pipe(
    switchMap(isAuthenticated => {
      if (isAuthenticated) {
        // Check if token is expired
        const token = store.selectSnapshot(AuthState.getToken);
        if (token) {
          const tokenPayload = JSON.parse(atob(token.split('.')[1]));
          const expirationTime = tokenPayload.exp * 1000; // Convert to milliseconds
          if (Date.now() >= expirationTime) {
            // Token is expired, try to refresh
            return store.dispatch(new RefreshToken()).pipe(
              map(() => true),
              catchError(() => of(router.createUrlTree(['/signin'], { queryParams: { redirect: state.url } })))
            );
          }
        }
        return of(true);
      }
      return of(router.createUrlTree(['/signin'], { queryParams: { redirect: state.url } }));
    })
  );
};

export const unauthenticatedGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
): Observable<boolean | UrlTree> => {
  const router = inject(Router);
  const authService = inject(AuthService);

  return authService.checkAuthState().pipe(
    take(1),
    map((isAuthenticated) => {
      // console.log('*** UNAUTHENTICATED GUARD *** isAuthenticated:', isAuthenticated);
      if (isAuthenticated) {
        // console.log('*** UNAUTHENTICATED GUARD *** Redirecting to broadcasts');
        return router.createUrlTree(['/broadcasts']);
      }
      // console.log('*** UNAUTHENTICATED GUARD *** Allowing access');
      return true;
    }),
    // Added: Catch any errors and allow access
    catchError((error) => {
      console.error('*** UNAUTHENTICATED GUARD *** Error:', error);
      return of(true);
    })
  );
};

// v1
// export const unauthenticatedGuard: CanActivateFn = (
//   route: ActivatedRouteSnapshot,
//   state: RouterStateSnapshot
// ):
//   | Observable<boolean | UrlTree>
//   | Promise<boolean | UrlTree>
//   | boolean
//   | UrlTree => {
//   const store = inject(Store);
//   const router = inject(Router);
//   const authService = inject(AuthService);

//   return store.select(AuthState.isAuthenticated).pipe(
//     take(1),
//     // tap((isAuthenticated) =>
//     //   console.log(`AuthGuard: isAuthenticated = ${isAuthenticated}`)
//     // ),
//     map((isAuthenticated) => {
//       console.log('*** UNAUTHENTICATED GUARD *** isAuthenticated:', isAuthenticated);

//       if (isAuthenticated) {
//         console.log('*** UNAUTHENTICATED GUARD *** Redirecting to broadcasts');

//         return router.createUrlTree(['/broadcasts']);
//       }
//       console.log('*** UNAUTHENTICATED GUARD *** Allowing access to signin');

//       return true;
//     })
//   );
// };


// v1
// export const authenticatedGuard: CanActivateFn = (
//   route: ActivatedRouteSnapshot,
//   state: RouterStateSnapshot
// ):
//   | Observable<boolean | UrlTree>
//   | Promise<boolean | UrlTree>
//   | boolean
//   | UrlTree => {
//   const store = inject(Store);
//   const router = inject(Router);
//   const authService = inject(AuthService);

//   return authService.isAuthenticated$.pipe(
//     take(1),
//     switchMap(isAuthenticated => {
//       if (!isAuthenticated) {
//         // Try to refresh the token if not authenticated
//         return authService.refreshToken().pipe(
//           map(() => true),
//           catchError(() => {
//             // If refresh fails, redirect to signin
//             return of(router.createUrlTree(['/signin'], {
//               queryParams: { redirect: state.url },
//             }));
//           })
//         );
//       }
//       return of(true);
//     })
//   );
// };






  // v1
//   return store.select(AuthState.isAuthenticated).pipe(
//     take(1),
//     map((isAuthenticated) => {
//       if (!isAuthenticated) {
//         return router.createUrlTree(['/signin'], {
//           queryParams: { redirect: state.url },
//         });
//       }
//       // console.log('AuthGuard: Authenticated');
//       return true;
//     })
//   );
// };
