// UsersService should handle:

// User-related operations: getMe, updateMe, etc.
// Interacting with the backend to fetch or update user details.
// Dispatching NGXS actions to update UserState based on the operations.

import { SupabaseService } from 'apps/vertice/src/app/shared/services/supabase.service';
import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject, of, from, Observable, firstValueFrom, throwError } from 'rxjs';
import { catchError, concatMap, delay, filter, finalize, map, retry, retryWhen, switchMap, take, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { Auth, UserCreateDto, UserPasswordUpdateDto, UserProgress } from '@vertice/data'; // Import your Auth and User interfaces
import { User } from '@vertice/data';
import { AuthService } from '../auth';
import { Entity } from '@vertice/enums';
import { StateContext, Select, Store } from '@ngxs/store';
import { SetUser, UnsetUser, Authenticate, Logout, UserState } from '@vertice/state';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  public isLoading$ = new BehaviorSubject<boolean>(false);
  private userSubject = new BehaviorSubject<User | null>(null);
  public user$ = this.userSubject.asObservable();

  constructor(
    private store: Store,
    private supabase: SupabaseService,
    private authService: AuthService,
  ) {
    // this.getUserObject();
    this.initUser();
  }

  private initUser() {
    this.getUser().subscribe();
  }

  getUser(): Observable<User | null> {
    return this.store.select(UserState.getUser).pipe(
      take(1),
      switchMap(user => {
        if (user) {
          return of(user);
        } else {
          this.isLoading$.next(true);
          return this.authService.checkAuthState().pipe(
            switchMap(isAuthenticated => {
              if (isAuthenticated) {
                return from(this.supabase.getUser());
              }
              return of(null);
            }),
            map(supabaseUser => {
              if (supabaseUser) {
                const user: User = {
                  id: supabaseUser.id,
                  app_metadata: supabaseUser.app_metadata,
                  user_metadata: supabaseUser.user_metadata,
                  aud: supabaseUser.aud,
                  confirmation_sent_at: supabaseUser.confirmation_sent_at,
                  recovery_sent_at: supabaseUser.recovery_sent_at,
                  email_change_sent_at: supabaseUser.email_change_sent_at,
                  new_email: supabaseUser.new_email,
                  new_phone: supabaseUser.new_phone,
                  invited_at: supabaseUser.invited_at,
                  action_link: supabaseUser.action_link,
                  email: supabaseUser.email,
                  phone: supabaseUser.phone,
                  created_at: supabaseUser.created_at,
                  confirmed_at: supabaseUser.confirmed_at,
                  email_confirmed_at: supabaseUser.email_confirmed_at,
                  phone_confirmed_at: supabaseUser.phone_confirmed_at,
                  last_sign_in_at: supabaseUser.last_sign_in_at,
                  role: supabaseUser.role,
                  updated_at: supabaseUser.updated_at,
                  identities: supabaseUser.identities,
                  factors: supabaseUser.factors,
                  progress: undefined,
                };
                this.userSubject.next(user);
                this.store.dispatch(new SetUser({ user, session: null }));
                return user;
              }
              this.userSubject.next(null);
              this.store.dispatch(new UnsetUser());
              return null;
            }),
            catchError(error => {
              console.error('Error loading current user:', error);
              this.userSubject.next(null);
              this.store.dispatch(new UnsetUser());
              return of(null);
            }),
            finalize(() => {
              this.isLoading$.next(false);
            })
          );
        }
      })
    );
  }

  // Utility function to get the loading state
  async getUserObject() {
    this.isLoading$.next(true);

    try {
      let user: User | null = null;
      const supabaseUser = await this.supabase.getUser();

      if (supabaseUser) {
        user = {
          id: supabaseUser.id,
          app_metadata: supabaseUser.app_metadata,
          user_metadata: supabaseUser.user_metadata,
          aud: supabaseUser.aud,
          confirmation_sent_at: supabaseUser.confirmation_sent_at,
          recovery_sent_at: supabaseUser.recovery_sent_at,
          email_change_sent_at: supabaseUser.email_change_sent_at,
          new_email: supabaseUser.new_email,
          new_phone: supabaseUser.new_phone,
          invited_at: supabaseUser.invited_at,
          action_link: supabaseUser.action_link,
          email: supabaseUser.email,
          phone: supabaseUser.phone,
          created_at: supabaseUser.created_at,
          confirmed_at: supabaseUser.confirmed_at,
          email_confirmed_at: supabaseUser.email_confirmed_at,
          phone_confirmed_at: supabaseUser.phone_confirmed_at,
          last_sign_in_at: supabaseUser.last_sign_in_at,
          role: supabaseUser.role,
          updated_at: supabaseUser.updated_at,
          identities: supabaseUser.identities,
          factors: supabaseUser.factors,
          progress: undefined,
        };
        this.userSubject.next(user);
      } else {
        console.log('No user data found');
        this.store.dispatch(new UnsetUser());
        this.userSubject.next(null);
      }
    } catch (error) {
      console.error('Error loading current user:', error);
      this.store.dispatch(new UnsetUser());
      this.userSubject.next(null);
    } finally {
      this.isLoading$.next(false);
    }
  }

  async getNewUserObject() {
    this.isLoading$.next(true);

    try {
      let user: User | null = null;
      const supabaseUser = await this.supabase.getUser();

      if (supabaseUser) {
        user = {
          id: supabaseUser.id,
          app_metadata: supabaseUser.app_metadata,
          user_metadata: supabaseUser.user_metadata,
          aud: supabaseUser.aud,
          confirmation_sent_at: supabaseUser.confirmation_sent_at,
          recovery_sent_at: supabaseUser.recovery_sent_at,
          email_change_sent_at: supabaseUser.email_change_sent_at,
          new_email: supabaseUser.new_email,
          new_phone: supabaseUser.new_phone,
          invited_at: supabaseUser.invited_at,
          action_link: supabaseUser.action_link,
          email: supabaseUser.email,
          phone: supabaseUser.phone,
          created_at: supabaseUser.created_at,
          confirmed_at: supabaseUser.confirmed_at,
          email_confirmed_at: supabaseUser.email_confirmed_at,
          phone_confirmed_at: supabaseUser.phone_confirmed_at,
          last_sign_in_at: supabaseUser.last_sign_in_at,
          role: supabaseUser.role,
          updated_at: supabaseUser.updated_at,
          identities: supabaseUser.identities,
          factors: supabaseUser.factors,
          progress: undefined,
        };
        // added unset user here
        this.store.dispatch(new UnsetUser());
        this.userSubject.next(user);
      } else {
        console.log('No user data found');
        this.store.dispatch(new UnsetUser());
        this.userSubject.next(null);
      }
    } catch (error) {
      console.error('Error loading current user:', error);
      this.userSubject.next(null);
    } finally {
      this.isLoading$.next(false);
    }
  }


  // backup
  // async getUserObject() {
  //   this.isLoading$.next(true);

  //   try {
  //     let user: User | null = null;
  //     const supabaseUser = await this.supabase.getUser();

  //     if (supabaseUser) {
  //       user = {
  //         id: supabaseUser.id,
  //         app_metadata: supabaseUser.app_metadata,
  //         user_metadata: supabaseUser.user_metadata,
  //         aud: supabaseUser.aud,
  //         confirmation_sent_at: supabaseUser.confirmation_sent_at,
  //         recovery_sent_at: supabaseUser.recovery_sent_at,
  //         email_change_sent_at: supabaseUser.email_change_sent_at,
  //         new_email: supabaseUser.new_email,
  //         new_phone: supabaseUser.new_phone,
  //         invited_at: supabaseUser.invited_at,
  //         action_link: supabaseUser.action_link,
  //         email: supabaseUser.email,
  //         phone: supabaseUser.phone,
  //         created_at: supabaseUser.created_at,
  //         confirmed_at: supabaseUser.confirmed_at,
  //         email_confirmed_at: supabaseUser.email_confirmed_at,
  //         phone_confirmed_at: supabaseUser.phone_confirmed_at,
  //         last_sign_in_at: supabaseUser.last_sign_in_at,
  //         role: supabaseUser.role,
  //         updated_at: supabaseUser.updated_at,
  //         identities: supabaseUser.identities,
  //         factors: supabaseUser.factors,
  //         progress: undefined,
  //       };
  //       this.store.dispatch(new FetchUserProgress(user));
  //       this.userSubject.next(user); // Update the BehaviorSubject with the user or null
  //     } else {
  //       // Dispatch UnsetUser action if no user is found
  //       console.log('No user data found');
  //       this.store.dispatch(new UnsetUser());
  //     }
  //   } catch (error) {
  //     console.error('Error loading current user:', error);
  //     this.userSubject.next(null); //
  //   } finally {
  //     this.isLoading$.next(false);
  //   }
  // }

  // fetchAndUpdateUserProgress(user: User, retryCount = 3) {
  //   this.userProgressQuery
  //     .fetchUserProgress(user)
  //     .pipe(
  //       retryWhen((errors) =>
  //         errors.pipe(
  //           concatMap((error, index) => {
  //             if (index < retryCount - 1) {
  //               console.log(
  //                 `Retrying fetch and update user progress (attempt ${
  //                   index + 1
  //                 })`
  //               );
  //               return of(error);
  //             }
  //             return throwError(error);
  //           }),
  //           delay(1000)
  //         )
  //       )
  //     )
  //     .subscribe((progress) => {
  //       // this.userProgressQuery.fetchUserProgress(user).subscribe((progress) => {
  //       const updatedUser = { ...user, progress };
  //       this.userSubject.next(updatedUser);
  //       this.store.dispatch(new SetUser({ user: updatedUser }));
  //     });
  // }

  resetPasswordRequest(userIdOrEmail: string) {
    this.isLoading$.next(true);
    // return this.getHttp()
    //   .post<null>(
    //     this.baseUrl +
    //       `/user/${encodeURIComponent(userIdOrEmail)}/password_request`,
    //     {}
    //   )
    //   .pipe(
    //     catchError((err: HttpErrorResponse) => {
    //       return of(err);
    //     }),
    //     finalize(() => {
    //       this.isLoading$.next(false);
    //     })
    //   );
  }

  resetPassword(
    userIdOrEmail: string,
    token: string,
    data: UserPasswordUpdateDto
  ) {
    this.isLoading$.next(true);
    // return this.getHttp()
    //   .post<null>(
    //     this.baseUrl +
    //       `/user/${encodeURIComponent(userIdOrEmail)}/password/${token}`,
    //     data
    //   )
    //   .pipe(
    //     catchError((err: HttpErrorResponse) => {
    //       return of(err);
    //     }),
    //     finalize(() => {
    //       this.isLoading$.next(false);
    //     })
    //   );
  }
}

//   // OAuth sign in function
//   async signInWithOAuth(provider: 'google' | 'facebook' | 'github') {
//     // Redirect the user to sign in with the selected OAuth provider
//     const { user, session, error } = await this.supabase.auth.signIn({
//       provider,
//     }, {
//       // Options go here (like redirect URL)
//     });

//     // Handle the response: save the session, handle errors
//   }


// fetchUserProgressWithRetry(user: User) {
//   const maxRetries = 3;
//   let attempts = 0;

//   const fetchProgress = () => {
//     this.userProgressQuery.fetchUserProgress(user).subscribe({
//       next: (userProgress) => {
//         user.progress = userProgress;
//         this.store.dispatch(new SetUser({ user: user, session: undefined }));
//         this.userSubject.next(user);
//       },
//       error: (error) => {
//         if (attempts < maxRetries) {
//           setTimeout(() => {
//             attempts++;
//             fetchProgress(); // Retry after a delay
//           }, 1000);
//         } else {
//           console.error('Error fetching user progress after retries:', error);
//           this.userSubject.next(user);
//         }
//       },
//     });
//   };

//   fetchProgress();
// }
