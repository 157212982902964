import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { HttpClient } from '@angular/common/http';
import {
  tap,
  catchError,
  from,
  Observable,
  switchMap,
  throwError,
  map,
  take,
  isObservable,
  of,
  EMPTY,
  finalize,
  mergeMap,
  retryWhen,
  timer,
  retry
} from 'rxjs';
import { AuthService, AuthState, BaseCrudQuery, UserState, UsersService } from '@vertice/state';
import { SupabaseService } from 'apps/vertice/src/app/shared/services/supabase.service';
import { Broadcasts, BroadcastMessages, User, StudentDetails, StudentDetailsAndCourses, BroadcastRecipient, Departments } from '@vertice/data';
import { environment } from 'apps/vertice/src/environments/environment';
import { Router } from '@angular/router';
// import { retryBackoff } from 'backoff-rxjs';
import { PostgrestResponse, PostgrestError } from '@supabase/supabase-js';

type SupabaseResponse = PostgrestResponse<BroadcastMessages>;

@Injectable({
  providedIn: 'root'
})
export class WhatsappSenderQuery extends BaseCrudQuery<any> {

  private baseUrl = environment.apiBaseUrl;

  constructor(
    store: Store,
    supabaseService: SupabaseService,
    authService: AuthService,
    router: Router,
    userService: UsersService,
    private http: HttpClient
  ) {
    super(store, supabaseService, authService, router, userService);
  }

  // !CRUD Broadcasts

  upsertBroadcast(broadcastData: Partial<Broadcasts>, broadcastId: string): Observable<Broadcasts> {
    const fullBroadcastData = { ...broadcastData, id: broadcastId };
    return this.upsert<Broadcasts>('broadcasts', fullBroadcastData).pipe(
      map(broadcasts => {
        if (broadcasts.length === 0) {
          throw new Error('Failed to upsert broadcast');
        }
        return broadcasts[0];
      })
    );
  }

  updateBroadcastStatus(broadcastId: string, data: Partial<Broadcasts>): Observable<Broadcasts> {
    return this.update<Broadcasts>('broadcasts', data, `id.eq.${broadcastId}`).pipe(
      map(broadcasts => {
        if (broadcasts.length === 0) {
          throw new Error('Failed to update broadcast');
        }
        return broadcasts[0];
      })
    );
  }



  deleteBroadcast(broadcastId: string): Observable<void> {
    return this.delete('broadcasts', broadcastId).pipe(
      // tap(() => console.log('***QUERY*** Successfully deleted broadcast', broadcastId)),
      catchError(error => {
        console.error('***QUERY*** Error deleting broadcast:', error);
        return throwError(() => new Error(`Failed to delete broadcast: ${error.message}`));
      })
    );
  }

  getAllBroadcasts(): Observable<Broadcasts[]> {
    console.log('***QUERY*** Getting all broadcasts');
    return this.getAll<Broadcasts>('broadcasts', undefined, undefined, false).pipe(
      tap(broadcasts => console.log('***QUERY*** Retrieved broadcasts:', broadcasts)),
      catchError(error => {
        console.error('***QUERY*** Error getting all broadcasts:', error);
        return throwError(() => error);
      })
    );
  }



  getBroadcastsByUser(): Observable<Broadcasts[]> {
    return this.getAll<Broadcasts>('broadcasts');
  }


  getBroadcastsByStatus(status: string): Observable<Broadcasts[]> {
    const filter = `status.eq.${status}`;
    return this.getAll<Broadcasts>('broadcasts', filter);
  }


  getBroadcastById(broadcastId: string): Observable<Broadcasts> {
    return this.getOne<Broadcasts>('broadcasts', `id.eq.${broadcastId}`);
  }

  getBroadcastWithRecipients(broadcastId: string): Observable<any[]> {
    return this.userService.getUser().pipe(
      switchMap(user => {
        if (!user) {
          console.error('***QUERY getBroadcastWithRecipients*** No authenticated user');
          return EMPTY;
        }
        return from(
          this.supabase
            .from('broadcasts')
            .select(`
              *,
              broadcast_contact_assignments (
                *,
                students:student_id (*),
                contacts:contact_id (*)
              )
            `)
            .eq('id', broadcastId)
            .eq('user_id', user.id)
            .order('created_at', { ascending: false }) 
        );
      }),
      map(response => {
        if (response.error) {
          console.error(`***QUERY getBroadcastWithRecipients*** Supabase error for broadcast ${broadcastId}:`, JSON.stringify(response.error, null, 2));
          throw response.error;
        }        
        return response.data && response.data.length > 0 ? response.data[0] : null;
      }),
      catchError(error => {
        console.error('***QUERY getBroadcastWithRecipients***  Error fetching broadcast with recipients:', error);
        return throwError(() => error);
      })
    );
  }


  getBroadcastMessages(broadcastId: string): Observable<BroadcastMessages[]> {
    // console.log('***QUERY*** getBroadcastMessages called', broadcastId);
    return this.getAll<BroadcastMessages>(
      'broadcast_messages',
      `broadcast_id.eq.${broadcastId}`,
      'sequence_order',
      false
    ).pipe(
      catchError(error => {
        console.error('***QUERY*** Error in getBroadcastMessages:', error);
        return throwError(() => new Error('Failed to fetch broadcast messages'));
      })
    );
  }



  // !PREPARE & SEND BROADCASTS

  createBroadcastRecipient(recipient: {
    broadcastId: string,
    studentId: string,
    contactId: string,
    contactCodigo: string,
    contactName: string,
    contactPhone: string,
    contactType: string
  }): Observable<BroadcastRecipient> {
    // console.log('***QUERY*** createBroadcastRecipient called', JSON.stringify(recipient, null, 2));
    return this.userService.getUser().pipe(
      switchMap(user => {
        if (!user) {
          console.error('***QUERY*** User not authenticated');
          return throwError(() => new Error('User not authenticated'));
        }
        const broadcastRecipient = {
          broadcast_id: recipient.broadcastId,
          student_id: recipient.studentId,
          contact_id: recipient.contactId,
          contact_name: recipient.contactName,
          contact_phone: recipient.contactPhone,
          contact_type: recipient.contactType,
          contact_codigo: recipient.contactCodigo,
          status: 'pending'
        };
        console.log('***QUERY*** Creating broadcast recipient', JSON.stringify(broadcastRecipient, null, 2));

        // Use upsert instead of insert to handle potential duplicates
        return from(this.supabase
          .from('broadcast_contact_assignments')
          .insert([broadcastRecipient])
          .select()
        ).pipe(
          tap((response: PostgrestResponse<BroadcastRecipient>) => {
            console.log('***QUERY*** Full Supabase response:', JSON.stringify(response, null, 2));
            if (response.error) {
              console.error('***QUERY*** Supabase error details:', JSON.stringify(response.error, null, 2));
              console.error('***QUERY*** Error status:', response.status);
              console.error('***QUERY*** Error message:', response.error.message);
              console.error('***QUERY*** Error details:', response.error.details);
            }
          }),
          map((response: PostgrestResponse<BroadcastRecipient>) => {
            if (response.error) {
              console.error('***QUERY*** Supabase error:', JSON.stringify(response.error, null, 2));
              throw response.error;
            }
            if (!response.data || response.data.length === 0) {
              throw new Error('No data returned from upsert operation');
            }
            console.log('***QUERY*** Broadcast recipient created:', JSON.stringify(response.data[0], null, 2));
            return response.data[0];
          }),
          catchError((error: PostgrestError | Error) => {
            console.error('***QUERY*** Error creating broadcast recipient:', JSON.stringify(error, null, 2));
            if ('code' in error && error.code === '23505') {
              console.warn('***QUERY*** Duplicate key violation. This might be due to a race condition.');
            }
            return throwError(() => error);
          })
        );
      })
    );
  }
  createBroadcastMessage(message: BroadcastMessages): Observable<BroadcastMessages> {
    console.log('***QUERY*** createBroadcastMessage called', message);
    return this.userService.getUser().pipe(
      switchMap(user => {
        if (!user) {
          console.error('***QUERY*** User not authenticated');
          return throwError(() => new Error('User not authenticated'));
        }
        console.log('***QUERY*** Creating broadcast message in Supabase');

        const messageType = message.attachments && message.attachments.length > 0 ? 'media' : 'text';
        const messageToInsert: Partial<BroadcastMessages> = {
          id: message.id || crypto.randomUUID(),
          broadcast_id: message.broadcast_id,
          content: message.content,
          original_content: message.original_content || message.content,
          type: messageType,
          sequence_order: message.sequence_order,
          attachments: message.attachments,
          friendly_name: message.friendly_name,
          // friendly_name: this.sender generateFriendlyName(message.broadcast_id, message.id),

          variables: message.variables ? JSON.stringify(message.variables) : undefined,
        };
        console.log('***QUERY*** Message being sent to Supabase:', JSON.stringify(messageToInsert, null, 2));

        return from(this.supabase
          .from('broadcast_messages')
          .upsert(messageToInsert, {
            onConflict: 'id',
            ignoreDuplicates: false
          })
          .select()
        ).pipe(
          tap((response: SupabaseResponse) => {
            console.log('***QUERY*** createBroadcastMessage full response:', JSON.stringify(response, null, 2));
            if (response.error) {
              console.error('***QUERY*** Supabase error details:', JSON.stringify(response.error, null, 2));
              console.error('***QUERY*** Error status:', response.status);
              console.error('***QUERY*** Error message:', response.error.message);
              console.error('***QUERY*** Error details:', response.error.details);
            }
          }),
          map((response: SupabaseResponse) => {
            if (response.error) {
              throw new Error(`Supabase error: ${response.error.code} - ${response.error.message} - ${JSON.stringify(response.error.details)}`);
            }
            if (!response.data || response.data.length === 0) {
              throw new Error('No data returned from upsert operation');
            }
            console.log('***QUERY*** Broadcast message created:', JSON.stringify(response.data[0], null, 2));
            return response.data[0] as BroadcastMessages;
          })
        );
      }),
      catchError(error => {
        console.error('***QUERY*** Error creating broadcast message:', error);
        // return throwError(() => error);
        return throwError(() => new Error(`Failed to create broadcast message: ${error.message}`));

      })
    );
  }



  // v2 with user
  prepareBroadcast(broadcastId: string, isScheduled: boolean = false): Observable<any> {
    this.updateBroadcastStatus(broadcastId, { status: 'pending' } as Broadcasts);

    return this.userService.getUser().pipe(
      switchMap(user => {
        if (!user) return EMPTY;
        return this.http.post(`${this.baseUrl}/broadcasts/prepare/${broadcastId}`, { send_later: isScheduled, user_id: user.id });
      }),
      catchError(error => {
        console.error('Error preparing broadcast:', error);
        return throwError(() => error);
      })
    );
  }


  scheduleBroadcast(broadcastId: string, scheduledTime: string): Observable<any> {
    console.log('***QUERY*** scheduleBroadcast called', { broadcastId, scheduledTime });
    return this.http.post(`${this.baseUrl}/broadcasts/schedule/${broadcastId}`, { scheduled_time: scheduledTime }).pipe(
      tap(result => console.log('***QUERY*** Broadcast scheduled:', result)),
      catchError(error => {
        console.error('***QUERY*** Error scheduling broadcast:', error);
        return throwError(() => error);
      })
    );
  }

  // template status

  getTemplateStatuses(broadcastId: string): Observable<{ [key: string]: { status: string, rejection_reason?: string } }> {
    return this.http.get<{ [key: string]: { status: string, rejection_reason?: string } }>(`${this.baseUrl}/broadcasts/${broadcastId}/template-statuses`).pipe(
      catchError(error => {
        console.error('Error fetching template statuses:', error);
        return throwError(() => new Error('Failed to fetch template statuses'));
      })
    );
  }


  // ! Auto Reply

  getAllDepartments(): Observable<Departments[]> {
    console.log('***QUERY*** Getting all departments');
    return this.getAll<Departments>('departments', undefined, undefined, false).pipe(
      tap(broadcasts => console.log('***QUERY*** Retrieved departments:', broadcasts)),
      catchError(error => {
        console.error('***QUERY*** Error getting all departments:', error);
        return throwError(() => error);
      })
    );
  }

  // New method to create short URL
  private createShortUrl(broadcastId: string, forwardToNumber: string): Observable<string> {
    return this.http.post<{ short_url: string }>(
      `${this.baseUrl}/v1/broadcasts/${broadcastId}/short-url`,
      { forward_to_number: forwardToNumber }
    ).pipe(
      map(response => response.short_url)
    );
  }

}


// 