import { Component, EventEmitter, Input, Output } from '@angular/core';
// import { SelectDialogItem } from '@vertice/ui';
import {SelectDialogItem} from '@vertice/ui';

@Component({
  selector: 've-select-dialog-item',
  templateUrl: './select-dialog-item.component.html',
})
export class SelectDialogItemComponent {
  @Input() item!: SelectDialogItem;
  @Output() selectionChanged = new EventEmitter<boolean>();
}
