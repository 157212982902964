import { CommonModule } from '@angular/common';
import { InputMaskModule } from '@ngneat/input-mask';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TimeSelectComponent } from './time-select.component';
// import { TodoTypeIconModule } from '../todo-type-icon/todo-type-icon.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [TimeSelectComponent],
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    MatBadgeModule,
    RouterModule,
    // TodoTypeIconModule,
    TranslateModule,
    MatMenuModule,
    InputMaskModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
  ],
  exports: [TimeSelectComponent],
})
export class TimeSelectModule {}
