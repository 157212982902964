import { Injectable } from '@angular/core';
import { Observable, tap, finalize, Subscription } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class RxJsDebugService {
    private debugEnabled = true;

    enableDebugging(enable: boolean): void {
        this.debugEnabled = enable;
    }

    debug<T>(source: Observable<T>, context: string): Observable<T> {
        if (!this.debugEnabled) {
            return source;
        }

        console.log(`[${context}] Observable created`);

        return new Observable<T>(observer => {
            console.log(`[${context}] Subscribed`);

            const subscription = source.pipe(
                tap({
                    next: value => console.log(`[${context}] Next:`, value),
                    error: error => console.error(`[${context}] Error:`, error),
                    complete: () => console.log(`[${context}] Completed`)
                }),
                finalize(() => console.log(`[${context}] Finalized`))
            ).subscribe(observer);

            return () => {
                console.log(`[${context}] Unsubscribed`);
                subscription.unsubscribe();
            };
        });
    }

// Added: New method to debug multiple observables
debugMultiple(observables: { [key: string]: Observable<any> }): Observable < { [key: string]: any } > {
    return new Observable(observer => {
        console.log(`[Multiple Observables] Starting`);
        const results: { [key: string]: any } = {};
        const subscriptions: { [key: string]: Subscription } = {};

        for (const [key, obs] of Object.entries(observables)) {
            subscriptions[key] = this.debug(obs, key).subscribe({
                next: value => {
                    results[key] = value;
                    console.log(`[Multiple Observables] ${key} emitted:`, value);
                },
                error: err => {
                    console.error(`[Multiple Observables] ${key} error:`, err);
                    observer.error(err);
                },
                complete: () => {
                    console.log(`[Multiple Observables] ${key} completed`);
                    if (Object.keys(results).length === Object.keys(observables).length) {
                        observer.next(results);
                        observer.complete();
                    }
                }
            });
        }

        return () => {
            console.log(`[Multiple Observables] Unsubscribing from all`);
            Object.values(subscriptions).forEach(sub => sub.unsubscribe());
        };
    });
}
}