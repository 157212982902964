import {
  AfterViewInit,
  // ComponentFactoryResolver,
  Directive,
  Input,
  OnChanges,
  OnInit,
  Renderer2,
  SimpleChanges,
  ViewContainerRef,
} from '@angular/core';
import { ElementRef } from '@angular/core';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Directive({
  selector: 'button[laShowSpinner]',
})
// it was previously onviewinit, changed it but didnt fix error
export class SpinnerButtonDirective implements AfterViewInit, OnChanges {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('laShowSpinner') showSpinner: boolean | null | undefined;
  originalInnerText: string | undefined;
  originalInnerObj: any | undefined;
  spinner: MatProgressSpinner | undefined;

  childrenRef: any[] = [];

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private viewContainerRef: ViewContainerRef,
    // private componentFactoryResolver: ComponentFactoryResolver 
  ) {}

  ngAfterViewInit() {
    const componentRef = this.viewContainerRef.createComponent(MatProgressSpinner);

    this.spinner = componentRef.instance;

    // Configure the spinner
    this.spinner.strokeWidth = 3;
    this.spinner.diameter = 24;
    this.spinner.color = 'warn';
  // Append the spinner to the view
    this.viewContainerRef.insert(componentRef.hostView);
    // Hide the spinner
    this.renderer.setStyle(
      this.spinner._elementRef.nativeElement,
      'display',
      'none'
    );

    // Apply new styles to the button content's container
    const span = this.el.nativeElement.querySelector(
      '.mat-mdc-button-wrapper'
    ) as HTMLSpanElement;
    this.renderer.setStyle(span, 'display', 'flex');
    this.renderer.setStyle(span, 'align-items', 'center');
    this.renderer.setStyle(span, 'justify-content', 'center');
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      typeof changes['showSpinner'] === 'object' &&
      !changes['showSpinner'].isFirstChange()
    ) {
      this.checkStatus();
    }
  }

  checkStatus() {
    if (this.el.nativeElement.offsetParent === null) {
      return;
    }

    if (this.showSpinner === true) {
      // save original child refs
      (this.el.nativeElement as HTMLElement).firstChild?.childNodes.forEach(
        (i) => this.childrenRef.push(i)
      );

      // fix button size
      this.el.nativeElement.style.width = `${
        (this.el.nativeElement as HTMLElement).offsetWidth
      }px`;
      this.el.nativeElement.style.height = `${
        (this.el.nativeElement as HTMLElement).offsetHeight
      }px`;

      // remove children
      this.childrenRef.forEach((i) => {
        this.renderer.removeChild(
          (this.el.nativeElement as HTMLElement).firstChild,
          i
        );
      });

      // Append the spinner
      this.renderer.appendChild(
        this.el.nativeElement.firstChild,
        this.spinner?._elementRef.nativeElement
      );

      // Show the spinner
      this.renderer.setStyle(
        this.spinner?._elementRef.nativeElement,
        'display',
        'inherit'
      );

      // make circlce white
      if (this.el.nativeElement.computedStyleMap) {
        const textColor = this.el.nativeElement
          .computedStyleMap()
          .get('color')
          .toString();

        const circle = this.spinner?._elementRef.nativeElement.querySelector(
          'circle'
        ) as unknown as HTMLSpanElement;
        if (circle) this.renderer.setStyle(circle, 'stroke', textColor);
      }
    } else if (this.showSpinner === false) {
      // Hide the spinner
      this.renderer.setStyle(
        this.spinner?._elementRef.nativeElement,
        'display',
        'none'
      );

      // Remove the spinner
      this.renderer.removeChild(
        this.el.nativeElement.firstChild,
        this.spinner?._elementRef.nativeElement
      );

      // append the original children
      this.childrenRef.forEach((i) => {
        this.renderer.appendChild(
          (this.el.nativeElement as HTMLElement).firstChild,
          i
        );
      });
    }

    this.el.nativeElement.disabled = this.showSpinner;
  }
}
