<table mat-table [dataSource]="_dataSource!" class="w-full">

  <ng-container matColumnDef="_select">
    <th mat-header-cell *matHeaderCellDef>
      <mat-checkbox (change)="selectAll($event)">
      </mat-checkbox>
    </th>
    <td mat-cell *matCellDef="let row">
      <mat-checkbox [checked]='selection.isSelected(row)' (change)='onSelectChange($event,row)'>
      </mat-checkbox>
    </td>
  </ng-container>

  <ng-container *ngFor='let column of columns;let index=index' [matColumnDef]="column.key">
    <ng-container *ngIf='!column.customTemplate'>
      <th (contextmenu)="openMenu($event,column,contextMenu)" mat-header-cell *matHeaderCellDef>
        {{column.name}} </th>
      <td mat-cell *matCellDef="let element"> {{element? element[column.key]: ''}} </td>
    </ng-container>

    <ng-container *ngIf='column.customTemplate'>
      <th (contextmenu)="openMenu($event,column,contextMenu)" mat-header-cell *matHeaderCellDef>
        <ng-container  *ngIf="column.headerTemplate">
          <ng-container *ngTemplateOutlet="column.headerTemplate!">
          </ng-container>
        </ng-container>
        <ng-container *ngIf="!column.headerTemplate">
          {{column.name}}
        </ng-container>
        </th>
      <td mat-cell *matCellDef="let element;">
        <ng-container *ngTemplateOutlet="column.template!; context: {$implicit: element}">
        </ng-container>
      </td>
    </ng-container>

  </ng-container>



  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

<mat-paginator (page)="onPageChange($event)" [class.invisible]="!pagination" #paginator
  [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons>
</mat-paginator>


<div #trigger [style.left]="position.x" [style.top]="position.y" class="absolute invisible trigger"
  [matMenuTriggerFor]="contextMenu" (menuClosed)="onMenuClosed()"></div>
<mat-menu #contextMenu="matMenu" class="p-2">
  <button mat-menu-item (click)='sort(true)'> Sort A <mat-icon>arrow_right_alt</mat-icon> Z</button>
  <button mat-menu-item (click)='sort(false)'> Sort Z <mat-icon>arrow_right_alt</mat-icon>
    A</button>
  <button mat-menu-item (click)='showSelect()'>Toggle Select</button>
  <button mat-menu-item> <input class="w-full px-2" #searchBar (input)='search(searchBar.value)'
      type="text" (click)='$event.preventDefault();$event.stopPropagation()'
      [placeholder]="'Search'"></button>

  <div mat-menu class="p-2">
    <button class="float-right ml-2" mat-flat-button color='primary'>Go</button>
    <button class="float-right ml-2" color='warn' mat-stroked-button>{{'common.cancel' | translate}}</button>
    <button class="float-right ml-2" (click)='resetFilter(searchBar)' mat-flat-button>Reset</button>
  </div>
</mat-menu>
