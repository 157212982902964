import { Injectable } from '@angular/core';
import { Actions, ofActionDispatched } from '@ngxs/store';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import {
    GoTo,
    GoToHome,
    GoToContactLogin,
    GoToLogin,
    GoTo404,
    GoTo408,
    Show408,
    Show404,
    Show500,
    Show464
} from './router.actions';

@Injectable({
    providedIn: 'root'
})
export class RouterStateService {
    constructor(
        private actions$: Actions,
        private router: Router,
        private snackBar: MatSnackBar
    ) {
        this.setupActionListeners();
    }

    private setupActionListeners(): void {
        this.actions$.pipe(ofActionDispatched(GoTo)).subscribe(({ url }) =>
            this.router.navigateByUrl(url)
        );

        this.actions$.pipe(ofActionDispatched(GoToHome)).subscribe(() =>
            this.router.navigate(['/broadcasts'], { queryParamsHandling: 'preserve' })
        );

        this.actions$.pipe(ofActionDispatched(GoToContactLogin)).subscribe(({ redirect }) =>
            this.router.navigate(['/hub/login'], { queryParamsHandling: 'merge', queryParams: { redirect } })
        );

        this.actions$.pipe(ofActionDispatched(GoToLogin)).subscribe(({ redirect }) =>
            this.router.navigate(['/sigin'], { queryParams: { redirect } }).then(() => window.location.reload())
        );

        this.actions$.pipe(ofActionDispatched(GoTo404)).subscribe(() =>
            this.router.navigate(['/404'])
        );

        this.actions$.pipe(ofActionDispatched(GoTo408)).subscribe(() =>
            this.router.navigate(['/408'])
        );

        this.actions$.pipe(ofActionDispatched(Show408)).subscribe(() =>
            this.snackBar.open('API could not be reached.', 'close')
        );

        this.actions$.pipe(ofActionDispatched(Show404)).subscribe(() =>
            this.snackBar.open('API Resource not found.', 'close')
        );

        this.actions$.pipe(ofActionDispatched(Show500)).subscribe(() =>
            this.snackBar.open('Internal server error.', 'close')
        );

        this.actions$.pipe(ofActionDispatched(Show464)).subscribe(() =>
            this.snackBar.open('Contact using this email already exists.', 'close')
        );
    }
}
