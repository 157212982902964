import { Component, Input, OnInit, Output, forwardRef } from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { Subject, debounceTime } from 'rxjs';
import { Time } from '../time-select/time-select.component';
import { createMask } from '@ngneat/input-mask';

// todo: In v15, setDisabledState is always called when a ControlValueAccessor is attached. To opt-out of this behavior, use FormsModule.withConfig or ReactiveFormsModule.withConfig
// https://angular.io/guide/update-to-version-15#v15-bc-06
@Component({
  selector: 've-date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styles: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateTimePickerComponent),
      multi: true,
    },
  ],
})
export class DateTimePickerComponent implements OnInit, ControlValueAccessor {
  @Input() placeholder = '';
  @Input() name = '';

  @Input() noOptional = false;

  @Output() changed = new Subject<string | undefined>();
  debouncer: Subject<string | undefined> = new Subject<string | undefined>();

  // value = new Subject<string | undefined>();
  time: Time | undefined;
  date: string | undefined;
  timepickerDateControl = new FormControl();

  dateInputMask = createMask<string>({
    alias: 'datetime',
    inputFormat: 'dd/mm/yyyy',
    parser: (value: string) => {
      return value;
    },
  });

  constructor() {
    this.debouncer
      .pipe(debounceTime(3000))
      .subscribe((value) => this.changed.next(value));
  }

  ngOnInit() {
    this.timepickerDateControl.valueChanges.subscribe((v: any) => {
      if (!v) return;
      let valueAsString;

      if (v instanceof Date) {
        const d = v;
        valueAsString = new Date(
          d.getFullYear(),
          d.getMonth(),
          d.getDate(),
          d.getHours(),
          d.getMinutes() - d.getTimezoneOffset()
        ).toISOString();
        const dateString = valueAsString.split('T')[0];
        const dateArr = dateString.split('-');

        const day = dateArr[2];
        const month = +dateArr[1];
        const year = dateArr[0];

        this.date = day + '/' + month + '/' + year;
        // this.onChange();
        this.onChanged();
      } else {
        this.date = v;
      }
    });

    // this.value.subscribe((value) => {
    //   console.log('new value', value);
    //   const timeString = value?.split('T')[1]?.split(':');
    //   if (timeString) this.time = { h: timeString[0], m: timeString[1] };

    //   const rawDate = value?.split('T')[0].split('-');
    //   if (rawDate) {
    //     this.date = +rawDate[2] + '/' + rawDate[1] + '/' + +rawDate[0];
    //   }

    //   this.timepickerDateControl.setValue(this.date);
    // });

    // this.value.next(this.fieldValueObject?.value);
  }

  onChanged() {
    let value = undefined;
    if (this.date) {
      const values = this.date.split('/');
      const year = +values[2];
      const month = +values[1] - 1;
      const date = +values[0];

      if (!this.time) {
        this.time = { m: '00', h: '00' };
      }

      value = new Date(
        Date.UTC(year, month, date, +this.time?.h, +this.time?.m)
      ).toISOString();
    }

    this.value = value;
    this.debouncer.next(value);
  }

  timeSelected(time: Time) {
    if (!time) return;
    this.time = time;
    this.onChanged();
    //
  }

  getTodayFormatted() {
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1 + ''; // Months start at 0!
    let dd = today.getDate() + '';

    if (dd.length === 1) dd = '0' + dd;
    if (mm.length === 1) mm = '0' + mm;

    return dd + '/' + mm + '/' + yyyy;
  }

  openDatePicker(picker: MatDatepicker<any>) {
    picker.open();
  }

  onChange: any = () => {
    return;
  };
  onTouch: any = () => {
    return;
  };

  set value(val: any) {
    // this value is updated by programmatic changes if( val !== undefined && this.val !== val){
    if (val) {
      const valuesArr = val.split('T');
      const dateArr = valuesArr[0].split('-');
      this.date = dateArr[2] + '/' + dateArr[1] + '/' + dateArr[0];

      const timeString = valuesArr[1]?.split(':');
      console.log('val', val);
      console.log('timestring', timeString);
      if (timeString) {
        this.time = { h: timeString[0], m: timeString[1] };
      } else {
        this.time = { m: '00', h: '00' };
      }
    } else {
      this.date = undefined;
      this.time = undefined;
    }

    this.onChange(val);
    // this.changed.next(val);
    this.onTouch(val);
  }
  // this method sets the value programmatically
  writeValue(value: any) {
    this.value = value;
  }
  // upon UI element value changes, this method gets triggered
  registerOnChange(fn: any) {
    this.onChange = fn;
  }
  // upon touching the element, this method gets triggered
  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  change(event: any) {
    console.log(event);
  }
}
