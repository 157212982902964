import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { NgModule } from '@angular/core';
import { PageContentModule } from './page-content/page-content.module';
import { PageEmptyStateModule } from './page-empty-state/page-empty-state.module';
// import { PageHeaderModule } from './page-header/page-header.module';
// import { PageHeaderWithStepperModule } from './page-header-with-stepper/page-header-with-stepper.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    // PageHeaderModule,
    PageContentModule,
    PageEmptyStateModule,
    MatIconModule,
  ],
  exports: [
    // PageHeaderModule,
    PageContentModule,
    PageEmptyStateModule,
    // PageHeaderWithStepperModule,
  ],
})
export class PageModule {}
