// SUPABASE SERVICE (API)
import { Injectable, OnInit } from '@angular/core';
import {
  AuthChangeEvent,
  AuthSession,
  createClient,
  Session,
  SupabaseClient,
  // User,
} from '@supabase/supabase-js';
import { User } from '@vertice/data';
import { environment } from 'apps/vertice/src/environments/environment';
import { Observable, from, switchMap, catchError, throwError, map } from 'rxjs';

export interface Profile {
  id?: string;
  username: string;
  website: string;
  avatar_url: string;
}

@Injectable({
  providedIn: 'root',
})
export class SupabaseService {
  public supabase!: SupabaseClient;
  public rpc: any;
  _session: AuthSession | null = null;

  constructor() {

    if (!environment.supabaseUrl || !environment.supabaseServiceKey) {
      throw new Error('Supabase URL and service key must be set');
    }
    if (typeof window !== 'undefined') {
      this.supabase = createClient(environment.supabaseUrl, environment.supabaseServiceKey);
      console.log('supabase client created ✅');
    }

    this.rpc = (name: string, params: any) => this.supabase.rpc(name, params);

  }



  get session() {
    this.supabase.auth.getSession().then(({ data }) => {
      this._session = data.session;
    });
    return this._session;
  }

  // Method to get the current user session
  async getSession(): Promise<AuthSession | null> {
    const { data: session, error } = await this.supabase.auth.getSession();
    if (error) {
      throw new Error(error.message);
    }
    return this.session;
  }


  async getUser(): Promise<User | null> {
    try {
      const { data: { user }, error } = await this.supabase.auth.getUser();
      if (error) throw error;
      return user;
    } catch (error) {
      console.error('Error fetching user:', error);
      return null;
    }
  }

  from(table: string) {
    return this.supabase.from(table);
  }


  async refreshToken(refreshToken: string): Promise<string | null> {
    const { data, error } = await this.supabase.auth.refreshSession({
      refresh_token: refreshToken,
    });

    if (error || !data.session) {
      console.error('Error refreshing token:', error?.message || 'No session data');
      return null;
    }

    return data.session.access_token;
  }

  profile(user: User) {
    return this.supabase
      .from('profiles')
      .select(`username, website, avatar_url`)
      .eq('id', user.id)
      .single();
  }

  authChanges(
    callback: (event: AuthChangeEvent, session: Session | null) => void
  ) {
    return this.supabase.auth.onAuthStateChange(callback);
  }

  signInWithPassword(email: string, password: string) {
    return this.supabase.auth.signInWithPassword({ email, password });
  }

  // #create supabsae signupwithemail method
  signUpWithPassword(email: string, password: string) {
    return this.supabase.auth.signUp({ email, password });
  }

  async resetPasswordForEmail(
    email: string,
    redirectTo?: string
  ): Promise<void> {
    const { data, error } = await this.supabase
      .from('users')
      .select('email')
      .eq('email', email)
      .single();

    if (error) {
      throw new Error('Usuário não registrado. Crie uma conta');
    }

    if (!data) {
      throw new Error('Usuário não encontrado');
    }

    const { error: resetError } =
      await this.supabase.auth.resetPasswordForEmail(email, {
        redirectTo,
      });

    if (resetError) {
      throw new Error(resetError.message);
    }
  }

  async updateUserPassword(newPassword: string): Promise<void> {
    const { error } = await this.supabase.auth.updateUser({
      password: newPassword,
    });
    if (error) {
      throw new Error(error.message);
    }
  }

  // Files

  async uploadFile(bucketName: string, path: string, file: File): Promise<string> {
    const { data, error } = await this.supabase.storage
      .from(bucketName)
      .upload(path, file);

    if (error) {
      throw error;
    }
    console.log('***Supabase File Upload***', data);

    return data.path;
  }

  getFilePublicUrl(bucketName: string, path: string): string {
    const { data } = this.supabase.storage
      .from(bucketName)
      .getPublicUrl(path);

    return data.publicUrl;
  }

  

  async createSignedUploadUrl(bucketName: string, filePath: string): Promise<{ signedUrl: string; token: string; path: string }> {
    const { data, error } = await this.supabase.storage
      .from(bucketName)
      .createSignedUploadUrl(filePath);

    if (error) throw error;
    console.log('***Supabase Signed Upload URL Created***', data);

    return data;
  }

  async uploadToSignedUrl(bucketName: string, path: string, token: string, file: File): Promise<{ path: string }> {
    const { data, error } = await this.supabase.storage
      .from(bucketName)
      .uploadToSignedUrl(path, token, file);

    if (error) throw error;
    if (!data) throw new Error('***SUPABASE UploadToSignedUrL*** Upload failed: No data returned');
    console.log('***Supabase Upload to Signed URL***', data);

    return { path: data?.fullPath || data?.path };
  }

  async createSignedUrl(bucketName: string, path: string, expiresIn: number): Promise<string> {
    const { data, error } = await this.supabase.storage
      .from(bucketName)
      .createSignedUrl(path, expiresIn);

    if (error) throw error;
    console.log('***Supabase Signed URL***', data);

    return data.signedUrl;
  }


  signOut() {
    return this.supabase.auth.signOut();
  }

  updateProfile(profile: Profile) {
    const update = {
      ...profile,
      updated_at: new Date(),
    };

    return this.supabase.from('profiles').upsert(update);
  }

  downLoadImage(path: string) {
    return this.supabase.storage.from('avatars').download(path);
  }

  uploadAvatar(filePath: string, file: File) {
    return this.supabase.storage.from('avatars').upload(filePath, file);
  }

  // async signInWithProvider(provider: 'google' | 'github' | 'facebook') {
  //     const { user, session, error } = await this.supabase.auth.signInWithOAuth({ provider });
  //     if (error) throw error;
  //     return { user, session };
  // }
}
