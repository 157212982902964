import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
// import { TeamUserRole } from '@vertice/enums';

export type SelectDialogItem = {
  title: string;
  subTitle?: string;
  id: string;
  selected: boolean;
  // role?: TeamUserRole
  isSelectionDisabled: boolean
  teams?: string
};

@Component({
  selector: 've-select-dialog',
  templateUrl: './select-dialog.component.html',
  styles: [],
})
export class SelectDialogComponent {
  selectedItems: SelectDialogItem[] = [];
  constructor(
    private dialogRef: MatDialogRef<SelectDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      items: SelectDialogItem[];
      title: string;
      subTitle: string;
    }
  ) {}

  // selectionChanged(event: { item: SelectDialogItem; selected: boolean }) {
  //   if (event.selected) {
  //     this.selectedItems.push(event.item);
  //   } else {
  //     this.selectedItems = this.selectedItems.filter(
  //       (item) => item !== event.item
  //     );
  //   }
  // }

  close() {
    this.dialogRef.close();
  }

  submit() {
    this.dialogRef.close(
      this.data.items
        .filter((i) => i.selected)
        .map((item) => {
          return { id: item.id };
        })
    );
  }
}
