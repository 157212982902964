import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

@Component({
  selector: 've-add-options',
  templateUrl: './add-options.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddOptionsComponent implements OnInit {
  @Input() options: string[] | undefined;
  @Output() changed = new EventEmitter<string[]>();

  formArray: FormArray | undefined;

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.formArray = this.fb.array(
      this.options?.map((o) => this.fb.control(o, [Validators.required])) || []
    );
    this.formArray.valueChanges.subscribe((v) => {
      this.changed.next(v);
    });
  }

  addOption() {
    this.formArray?.push(this.fb.control('', [Validators.required]));
  }

  getFormGroup(control: AbstractControl) {
    return control as FormGroup;
  }

  deleteOption(index: number) {
    this.formArray?.removeAt(index);
  }

  get formArrayControls() {
    return this.formArray?.controls as FormControl[];
  }
}
