import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 've-chip-auto-complete',
  templateUrl: './chip-auto-complete.component.html',
  styles: [],
})
export class ChipAutoCompleteComponent {
  @Input() placeholder = '';
  @Input() bindLabel = '';
  @Input() bindValue = '';
  separatorKeysCodes: number[] = [];
  @Input() items: any[] = [];
  @Input() selectedItems: any[] = [];
  @Output() selectionChange = new EventEmitter<any>();
  @Input() bindSubtitle = '';
  @ViewChild('searchInput') searchInput:
    | ElementRef<HTMLInputElement>
    | undefined;

  constructor() {
    return;
  }

  ngOnChanges() {
    console.log(this.selectedItems);
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our fruit
    if (value) {
      this.selectedItems.push(value);
    }

    // Clear the input value
    event.chipInput?.clear();

    // this.fruitCtrl.setValue(null);
  }

  remove(fruit: string): void {
    const index = this.items.indexOf(fruit);

    if (index >= 0) {
      this.items.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    if (this.searchInput) {
      this.searchInput.nativeElement.value = '';
      this.selectedItems.push(event.option.value);
      this.selectionChange.emit(this.selectedItems);
    }
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.items.filter((fruit) =>
      fruit.toLowerCase().includes(filterValue)
    );
  }
}
