<ng-container *ngFor='let data of dataSource'>
  <ng-container  *ngTemplateOutlet="template!; context: {$implicit: data}"></ng-container>
</ng-container>

<mat-paginator [length]="dataSource?.length"
              [pageSize]="10"
              [pageSizeOptions]="[5, 10, 25, 100]"
              aria-label="Select page"
              (page)="onPageChange($event)">
</mat-paginator>
