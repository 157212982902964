import { Component } from '@angular/core';

@Component({
  selector: 've-page-content',
  templateUrl: './page-content.component.html',
  styles: [
  ]
})
export class PageContentComponent {


}
