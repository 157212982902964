import { Component, OnInit } from '@angular/core';

@Component({
  selector: 've-cell-input-number',
  templateUrl: './cell-input-number.component.html',
  styles: [
  ]
})
export class CellInputNumberComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
