import { AuthToken } from '../constants';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, catchError, switchMap, take, tap, throwError } from 'rxjs';
import { Store } from '@ngxs/store';
import { AuthService, AuthState, ClearToken } from '@vertice/state';
import { Router } from '@angular/router';
@Injectable({ providedIn: 'root' })
export class BaererInterceptor implements HttpInterceptor {
  constructor(private store: Store, private authService: AuthService, private router: Router) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // console.log('***BEARER INTERCEPTOR*** Intercepting request');
    return this.store.select(AuthState.getToken).pipe(
      take(1),
      switchMap((token) => {
        if (token) {
          // console.log('***BEARER INTERCEPTOR*** Token found, adding to request');
          const authReq = req.clone({
            headers: new HttpHeaders({
              Authorization: `Bearer ${token}`,
            }),
          });
          return next.handle(authReq).pipe(
            catchError((error) => {
              console.log('***BEARER INTERCEPTOR*** Error caught:', error);
              if (error.status === 401) {
                console.log('***BEARER INTERCEPTOR*** 401 error, handling unauthorized');
                return this.handleUnauthorizedError(req, next);
              }
              return throwError(() => error);
            })
          );
        } else {
          // console.warn('***BEARER INTERCEPTOR*** No token found, redirecting to signin');
          this.router.navigate(['/signin']);
          return EMPTY;
        }
      })
    );
  }

  private handleUnauthorizedError(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // console.log('***BEARER INTERCEPTOR*** Attempting to refresh token');
    return this.authService.refreshToken().pipe(
      switchMap((result) => {
        if (result.success && result.session) {
          // console.log('***BEARER INTERCEPTOR*** Token refreshed successfully');
          return this.store.select(AuthState.getToken).pipe(
            take(1),
            switchMap((newToken) => {
              if (newToken) {
                // console.log('***BEARER INTERCEPTOR*** Retrying request with new token');
                const authReq = req.clone({
                  headers: req.headers.set('Authorization', `Bearer ${newToken}`),
                });
                return next.handle(authReq);
              } else {
                // console.warn('***BEARER INTERCEPTOR*** No new token after refresh, redirecting to signin');
                this.router.navigate(['/signin']);
                return EMPTY;
              }
            })
          );
        } else {
          // console.warn('***BEARER INTERCEPTOR*** Token refresh failed, redirecting to signin');
          this.store.dispatch(new ClearToken());
          this.router.navigate(['/signin']);
          return EMPTY;
        }
      }),
      catchError((error) => {
        // console.error('***BEARER INTERCEPTOR*** Error during token refresh:', error);
        this.store.dispatch(new ClearToken());
        this.router.navigate(['/signin']);
        return EMPTY;
      })
    );
  }
}
  // v2
//   intercept(
//     req: HttpRequest<any>,
//     next: HttpHandler
//   ): Observable<HttpEvent<any>> {
//     return this.store.select(AuthState.getToken).pipe(
//       take(1),
//       switchMap((token) => {
//         if (token) {
//           // console.log('***BAERER INTERCEPTOR*** Token available:', token);
//           const authReq = req.clone({
//             headers: new HttpHeaders({
//               Authorization: `Bearer ${token}`,
//             }),
//             // setHeaders: {
//             //   Authorization: `Bearer ${token}`,
//             // },
//           });
//           // console.log('***BAERER INTERCEPTOR*** Outgoing request:', authReq);
//           return next.handle(authReq).pipe(
//             tap((event) => {
//               if (event instanceof HttpResponse) {
//                 // console.log('***BAERER INTERCEPTOR*** Response:', event);
//               }
//             }),
//             catchError((error) => {
//               console.error('***BAERER INTERCEPTOR*** Error:', error);
//               if (error.status === 401) {
//                 return this.handleUnauthorizedError(req, next);
//               }
//               return throwError(() => error);
//             })
//           );
//         } else {
//           console.warn('***BAERER INTERCEPTOR*** No token found in store');
//           return next.handle(req);
          
//         }
//       })
//     );
//   }


//   private handleUnauthorizedError(
//     req: HttpRequest<any>,
//     next: HttpHandler
//   ): Observable<HttpEvent<any>> {
//     // Changed: Remove the getRefreshToken selector, as we don't need the refresh token anymore
//     return this.authService.refreshToken().pipe(
//       switchMap((result) => {
//         // Changed: Check the result of refreshToken
//         if (result.success && result.session) {
//           return this.store.select(AuthState.getToken).pipe(
//             take(1),
//             switchMap((newToken) => {
//               if (newToken) {
//                 const authReq = req.clone({
//                   headers: req.headers.set('Authorization', `Bearer ${newToken}`),
//                 });
//                 return next.handle(authReq);
//               } else {
//                 return throwError(
//                   () => new Error('No token available after refresh')
//                 );
//               }
//             })
//           );
//         } else {
//           // If refresh was not successful, clear token and throw error
//           this.store.dispatch(new ClearToken());
//           return throwError(() => new Error('Token refresh failed'));
//         }
//       }),
//       catchError((error) => {
//         this.store.dispatch(new ClearToken());
//         return throwError(() => error);
//       })
//     );
//   }
// }

