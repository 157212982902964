import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Output,
  Self,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 've-searchable-list',
  templateUrl: './searchable-list.component.html',
  styles: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchableListComponent),
      multi: true,
    },
  ],
})
export class SearchableListComponent
  implements OnInit, ControlValueAccessor, OnChanges
{
  @Input() bindLabel = '';
  @Input() bindValue = '';
  @Input() items: any[] = [];
  @Input() createLabel = '';
  @Input() multiple = false;
  @Input() searchLabel = '';
  @Output() createClick = new EventEmitter();
  selected: any | undefined;
  @Input() bindSubtitle = '';

  ngOnChanges(): void {
    return;
  }

  constructor() {
    return;
  }

  ngOnInit(): void {
    return;
  }

  onChange: any = () => {
    return;
  };
  onTouch: any = () => {
    return;
  };

  set value(val: any) {
    // this value is updated by programmatic changes if( val !== undefined && this.val !== val){
    this.onChange(val);
    this.onTouch(val);
  }
  // this method sets the value programmatically
  writeValue(value: any) {
    this.value = value;
    this.selected = value ? value.map((val: any) => val[this.bindValue]) : [];
  }
  // upon UI element value changes, this method gets triggered
  registerOnChange(fn: any) {
    this.onChange = fn;
  }
  // upon touching the element, this method gets triggered
  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  selectionChanged(value: any) {
    if (this.bindValue) {
      this.writeValue(
        this.items.filter((val) => value.includes(val[this.bindValue]))
      );
    } else this.writeValue(value);
  }
}
