import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 've-toggle',
  templateUrl: './toggle.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ToggleComponent),
      multi: true,
    },
  ],
})
export class ToggleComponent implements ControlValueAccessor {
  @Input() title: string | undefined;
  @Input() subTitle: string | undefined;
  @Input() subTitleChecked: string | undefined;
  @Input() icon: string | undefined;
  checked = false;

  onChange: any = (e: boolean) => {
    this.checked = e;
  };
  onTouch: any = () => {
    //
  };

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(checked: boolean) {
    this.checked = checked;
  }

  onModelChange(e: boolean) {
    this.checked = e;

    this.onChange(e);
  }

  toggleClick() {
    return false;
  }
}
