import { CdkStep, CdkStepperModule } from '@angular/cdk/stepper';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { NgModule } from '@angular/core';
import { StepComponent } from './components/step/step.component';
import { StepperComponent } from './components/stepper/stepper.component';

@NgModule({
  declarations: [StepperComponent, StepComponent],
  imports: [CommonModule, CdkStepperModule, MatIconModule],
  exports: [StepperComponent, StepComponent, CdkStep],
})
export class StepperModule {}
