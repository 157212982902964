<div class="rounded border inline-block p-4 cursor-pointer w-44" (click)="onModelChange(!checked)">
  <div class="flex flex-col items-center ">
    <div class="rounded-full  w-16 h-16 flex items-center justify-center"
      [ngClass]="{'bg-primary-50': !checked, 'bg-secondary-700': checked}">
      <mat-icon [ngClass]="{'text-white': checked, 'text-gray-400': !checked}" *ngIf="icon">{{
        icon }}</mat-icon>
    </div>
    <div class="font-bold py-2"
      [ngClass]="{'text-primary-500': checked, 'text-gray-400': !checked}">{{ title }}</div>
    <div class="" [ngClass]="{'text-primary-700': checked, 'text-gray-400': !checked}">{{
      checked? subTitleChecked: subTitle }}</div>

    <mat-slide-toggle (click)="toggleClick()" class="example-margin" [color]="'accent'"
      [ngModel]="checked" (ngModelChange)="onModelChange($event)"></mat-slide-toggle>
  </div>
</div>
