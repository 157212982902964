import { CdkStepper } from '@angular/cdk/stepper';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 've-stepper',
  templateUrl: './stepper.component.html',
  styles: [],
  providers: [{ provide: CdkStepper, useExisting: StepperComponent }],
})
export class StepperComponent extends CdkStepper {
  @Input() hidebody = false;
  @Input() disableNavigation = false;
}
