<div class="flex flex-col ">
  <div class="mb-6" [ngClass]="{'mt-6': !isFirstSection}">
    <div class="">
      <mat-icon class="text-surface-medium mr-2 inline-block" *ngIf="icon">{{
        icon }}</mat-icon>
      <span class="text-surface-medium font-bold">{{ title || '' | translate }}</span>
    </div>
    <div class="text-surface-medium text-sm">{{ subTitle || '' | translate }}</div>
  </div>
  <ng-content></ng-content>
  <hr *ngIf="!isLastSection">
</div>
