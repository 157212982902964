<h2>{{'form_builder.configure_form.add_options' | translate}}</h2>
<p>{{'form_builder.configure_form.add_options_helper' | translate}}</p>
<form *ngIf="formArray" class="text-gray-400">
  <ng-container *ngFor="let optionForm of formArrayControls; let i = index">
    <div class="flex items-center pb-4 option-form-row">
      <span class="pr-4">{{i + 1}}.</span>
      <mat-form-field class="w-full pl-2 m-p-0-input">
        <input [formControl]='optionForm' placeholder="The placeholder text" matInput type="text">
      </mat-form-field>
      <mat-icon class="pl-4" (click)="deleteOption(i)">delete</mat-icon>
    </div>
  </ng-container>
  <button (click)="addOption()"
    class="w-full mb-3 text-center border-2 border-gray-400 border-dashed rounded">
    <div class="flex items-center pt-2 pb-2 ml-auto mr-auto justify-items-center w-36">
      <mat-icon>add_circle_outline</mat-icon>
      <span class="pl-2">{{'form_builder.configure_form.add_option' | translate}}</span>
    </div>
  </button>
</form>
