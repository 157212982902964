import { Component, Input } from '@angular/core';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';

type PaneType = 'left' | 'right';

@Component({
  selector: 've-slide-panel',
  templateUrl: './slide-panel.component.html',
  animations: [
    trigger('slide', [
      state('left', style({ transform: 'translateX(0)' })),
      state('right', style({ transform: 'translateX(-50%)' })),
      transition('* => *', animate(300)),
    ]),
  ],
  styles: [
    `
      :host {
        display: block;
        overflow: hidden;
        width: 50%;
      }

      .panes {
        height: 100%;
        width: 300%;

        display: flex;
        div {
          flex: 1;
        }
      }
    `,
  ],
})
export class SlidePanelComponent {
  @Input() activePane: PaneType = 'left';
}
