import { AddOptionsComponent } from './add-options/add-options.component';
import { ChipAutoCompleteComponent } from './chip-auto-complete/chip-auto-complete.component';
import { CommonModule } from '@angular/common';
import { DateTimePickerModule } from '../date-time-picker/date-time-picker.module';
import { DialogModule } from '../dialog/dialog.module';
import { FormSectionComponent } from './form-section/form-section.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { IconSelectComponent } from './icon-select/icon-select.component';
import { InlineErrorBoxComponent } from './inline-error-box/inline-error-box.component';
import { InlineSuccessBoxComponent } from './inline-success-box/inline-success-box.component';
import { InputMaskModule } from '@ngneat/input-mask';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import {
  MatProgressSpinner,
  MatProgressSpinnerModule,
  // MatSpinner,
} from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { NgModule } from '@angular/core';
// import {
//   NgxMatDatetimePickerModule,
//   NgxMatNativeDateModule,
//   NgxMatTimepickerModule,
// } from '@angular-material-components/datetime-picker';
// import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { SearchableListComponent } from './searchable-list/searchable-list.component';
import { SpinnerButtonDirective } from './spinner-button.directive';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    InlineErrorBoxComponent,
    SpinnerButtonDirective,
    InlineSuccessBoxComponent,
    // BgSelectComponent,
    // BgSelectSearchPipe,
    FormSectionComponent,
    SearchableListComponent,
    // DateTimePickerComponent,
    ChipAutoCompleteComponent,
    // IconSelectComponent,
    AddOptionsComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatInputModule,
    MatFormFieldModule,
    FormsModule,
    // NgxMatDatetimePickerModule,
    // NgxMatNativeDateModule,
    // NgxMatTimepickerModule,
    MatDatepickerModule,
    MatButtonModule,
    DialogModule,
    MatChipsModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    // NgxMatSelectSearchModule,
    InputMaskModule,
    TranslateModule,
    DateTimePickerModule,
  ],
  exports: [
    InlineErrorBoxComponent,
    SpinnerButtonDirective,
    InlineSuccessBoxComponent,
    // BgSelectComponent,
    FormSectionComponent,
    // DateTimePickerComponent,
    SearchableListComponent,
    ChipAutoCompleteComponent,
    // IconSelectComponent,
    AddOptionsComponent,
  ],
  // entryComponents: [MatProgressSpinner],
})
export class FormModule {}
