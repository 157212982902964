




import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpStatusCode } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { AuthToken } from '../constants';
import { CustomHttpStatus } from '@vertice/enums';
import { AuthService, AuthState, Logout, UnsetUser } from '@vertice/state';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private router: Router, private store: Store) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
      const unauthenticatedPaths = [
        '/signin',
        '/signup',
        '/forgot',
        '/verify',
        '/reset',
      ];

      if (
        unauthenticatedPaths.some((path) => this.router.url.includes(path))
      ) {
        return next.handle(req);
      }
    return this.store.select(AuthState.getToken).pipe(
      take(1),
      switchMap((token) => {
        if (token) {
          req = req.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`,
            },
          });
        }
        return next.handle(req).pipe(
          catchError((error: HttpErrorResponse) => {
            if (error.status === HttpStatusCode.Unauthorized) {
              console.log('AUTH INTERCEPTOR: Unauthorized')
              this.store.dispatch(new Logout());
              this.router.navigate(['/signin']);
            }
            return throwError(() => error);
          })
        );
      })
    );
  }
}


// @Injectable()
// export class ErrorInterceptor implements HttpInterceptor {
//   constructor(private router: Router) { }

//   intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
//     return next.handle(req).pipe(
//       catchError((error: HttpErrorResponse) => {
//         const url = this.router.url;

//         if (error.status === HttpStatusCode.Unauthorized) {
//           // Todo: change to sessin
//           localStorage.removeItem('session');
//           this.router.navigate(['/login'], { queryParams: { redirect: url } });
//         }

//         return throwError(() => error);
//       })
//     );
//   }
// }


// @Injectable()
// export class ErrorInterceptor implements HttpInterceptor {
//   constructor(private store: Store, private router: Router) { }

//   intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
//     return next.handle(req).pipe(
//       catchError((error: HttpErrorResponse) => {
//         const url = this.router.url;

//         if (error.status === HttpStatusCode.Unauthorized || (error.url?.endsWith('/me') && error.status === HttpStatusCode.NotFound)) {
//           localStorage.removeItem(AuthToken);
//           this.store.dispatch(new goToLogin({ redirect: url }));
//         } else if (error.url?.endsWith('/contact-me') && error.status === HttpStatusCode.NotFound) {
//           localStorage.removeItem(AuthToken);
//           this.store.dispatch(new goToContactLogin({ redirect: url }));
//         } else if ((error.status === HttpStatusCode.RequestTimeout || error.status === 0) && !error.url?.endsWith('/api/update')) {
//           this.store.dispatch(new show408());
//         } else if (error.status === HttpStatusCode.NotFound) {
//           this.store.dispatch(new show404());
//         } else if (error.status === HttpStatusCode.InternalServerError) {
//           this.store.dispatch(new show500());
//         } else if (error.status === CustomHttpStatus.CONTACT_ALREADY_EXISTS) {
//           this.store.dispatch(new show464());
//         }

//         return throwError(() => error);
//       })
//     );
//   }
// }
