// base.state.ts
import { State, Action, StateContext } from '@ngxs/store';

// Define your actions
export class AddToStoreAction {
  static readonly type = '[Base] Add to Store';
  constructor(public payload: any) {}
}

export class UpdateStoreAction {
  static readonly type = '[Base] Update Store';
  constructor(public payload: any) {}
}

export class RemoveFromStoreAction {
  static readonly type = '[Base] Remove from Store';
  constructor(public payload: any) {}
}

export class UpdateInStoreAction {
    static readonly type = '[Base] Update in Store';
    constructor(public payload: { id: any, newValues: any }) {}
}

// Define your state model
export interface BaseStateModel {
  data: any[];
}


// Define your state
@State<BaseStateModel>({
  name: 'base',
  defaults: {
    data: []
  }
})
export class BaseState {
  @Action(AddToStoreAction)
  addToStore(ctx: StateContext<BaseStateModel>, action: AddToStoreAction) {
    const state = ctx.getState();
    ctx.patchState({
      data: [...state.data, action.payload]
    });
  }

  @Action(UpdateStoreAction)
  updateStore(ctx: StateContext<BaseStateModel>, action: UpdateStoreAction) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      data: action.payload
    });
  }

  @Action(RemoveFromStoreAction)
  removeFromStore(ctx: StateContext<BaseStateModel>, action: RemoveFromStoreAction) {
    const state = ctx.getState();
    ctx.patchState({
      data: state.data.filter(item => item.id !== action.payload)
    });
  }

    @Action(UpdateInStoreAction)
    updateInStore(ctx: StateContext<BaseStateModel>, action: UpdateInStoreAction) {
        const state = ctx.getState();
        ctx.setState({
            ...state,
            data: state.data.map(item => item.id === action.payload.id ? { ...item, ...action.payload.newValues } : item)
        });
    }
}
