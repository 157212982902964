import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';


@Component({
  templateUrl: 'initials.component.html',
  selector: 've-initials',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InitialsComponent implements OnInit {
  @Input() firstName? : string
  @Input() lastName? : string
  @Input() url? : string
  @Input() backgroundColorClass?: string
  fullName?: string[] = []

  ngOnInit() {
    if (!this.lastName && this.isFirstNameEqualsFullName) {
      this.lastName = this.fullName && this.fullName[1]
    }
  }

  get isFirstNameEqualsFullName(): boolean | undefined {
    this.fullName = this.firstName?.split(' ')
    return this.fullName && this.fullName?.length > 1
  }
}
