import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InitialsComponent } from './components/initials.component';
import { MatTooltipModule } from "@angular/material/tooltip";

@NgModule({
  declarations: [InitialsComponent],
  imports: [
    CommonModule,
    MatTooltipModule,
  ],
  exports: [InitialsComponent]
})

export class InitialsModule {}
