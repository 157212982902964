import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Input,
  TemplateRef,
} from '@angular/core';

@Component({
  selector: 've-dialog-stepper-item',
  templateUrl: './dialog-stepper-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogStepperItemComponent {
  @Input()
  public text = '';

  @Input()
  public position!: number;

  @Input()
  public current!: number;

  @ContentChild(TemplateRef)
  public templateRef!: TemplateRef<unknown>;

  public get active(): boolean {
    return this.position === this.current;
  }
}
