import { Injectable } from '@angular/core';
import { User, UserProgress } from '@vertice/data';
import { State, Selector, Action, StateContext, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { GetAuthenticatedUser, SetUser, UnsetUser } from './users.actions';
import { catchError, Observable, of, switchMap } from 'rxjs';
import { AuthState, AuthService } from '@vertice/state';

// user.state.ts
export interface UserStateModel {
  user?: User;
  session?: any;
  isLoading: boolean;
  error?: any;
}

@State<UserStateModel>({
  name: 'user',
  defaults: {
    user: undefined,
    session: undefined,
    isLoading: false,
    error: null,
  },
})
@Injectable()
export class UserState {
  constructor(private authService: AuthService, private store: Store) { }

  @Selector()
  static getUser(state: UserStateModel): User | undefined {
    return state.user;
  }
  // v1
  // static getUser(state: UserStateModel) {
  //   return state.user;
  // }

  @Selector()
  static getSession(state: UserStateModel) {
    return state.session;
  }
  @Selector()
  static isLoggedIn(state: UserStateModel): boolean {
    return !!state.user;
  }

  @Selector([UserState.getUser, AuthState.isAuthenticated])
  static getAuthenticatedUser(state: UserStateModel, isAuthenticated: boolean): Observable<User | null> {
    return of(isAuthenticated && state.user ? state.user : null);
  }

  @Action(SetUser)
  setUser(ctx: StateContext<UserStateModel>, action: SetUser) {
    const { user, session } = action.payload;

    ctx.patchState({
      user,
      session: session !== undefined ? session : ctx.getState().session,
    });
  }

  @Action(UnsetUser)
  unsetUser(ctx: StateContext<UserStateModel>) {
    ctx.setState(
      patch({
        user: undefined,
        session: undefined,
        isLoading: false,
        error: null,
      })
    );
  }


  // ?new stuff
  @Action(GetAuthenticatedUser)
  getAuthenticatedUser(ctx: StateContext<UserStateModel>) {
    return this.authService.refreshToken().pipe(
      switchMap(result => {
        if (result.success && result.session?.user) {
          const user = result.session.user;
          ctx.patchState({
            user,
            session: result.session,
            isLoading: false,
            error: null
          });
          console.log('***USER STATE*** User authenticated and state updated:', user);
          return of(user);
        } else {
          console.log('***USER STATE*** Authentication failed or no user data');
          ctx.patchState({
            user: undefined,
            session: undefined,
            isLoading: false,
            error: 'Authentication failed'
          });
          return of(null);
        }
      }),
      catchError((error) => {
        console.error('***USER STATE*** Error getting authenticated user:', error);
        ctx.patchState({
          user: undefined,
          session: undefined,
          isLoading: false,
          error: error.message || 'Unknown error occurred'
        });
        return of(null);
      })
    );
  }
}

export interface setUser {
  readonly type: 'SET_USER';
  user?: User;
  session?: any;
  isLoading?: boolean;
  error?: any;
}

export interface unsetUser {
  readonly type: 'UNSET_USER';
}
