import { Component, Input } from '@angular/core';

@Component({
  selector: 've-form-section',
  templateUrl: './form-section.component.html',
})
export class FormSectionComponent {
  @Input() title: string | undefined;
  @Input() subTitle: string | undefined;
  @Input() icon: string | undefined;
  @Input() isLastSection = false;
  @Input() isFirstSection = false;
}
