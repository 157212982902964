import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable,throwError, pipe, catchError, forkJoin, from, map, of, switchMap, delay, retryWhen, scan, retry, timer } from 'rxjs';
import { environment } from 'apps/vertice/src/environments/environment';
import { BroadcastMessages, FinancialPlanItem } from '@vertice/data';
import { SupabaseService } from 'apps/vertice/src/app/shared/services/supabase.service';
import { WhatsappSenderState } from '@vertice/state';
import { Store } from '@ngxs/store';


@Injectable({
    providedIn: 'root',
})
export class FileUploadQuery {
    private baseUrl = environment.apiBaseUrl;
    private bucketName = 'broadcast-attachments';

    constructor(private supabase: SupabaseService, private store: Store) { }

    uploadFileAndGetPublicUrl(file: File): Observable<string> {
        const fileName = this.getFileName(file);
        const filePath = `${Date.now()}_${fileName}`;

        return from(this.supabase.uploadFile(this.bucketName, filePath, file)).pipe(
            switchMap(path => from(this.supabase.supabase.storage
                .from(this.bucketName)
                .createSignedUrl(path, 60 * 60 * 24 * 7))), // 7 days expiration
            map(({ data }) => data!.signedUrl),
            catchError(error => {
                console.error('Error in file upload process:', error);
                return throwError(() => new Error(`File upload failed: ${error.message || 'Unknown error'}`));
            })
        );
    }


    uploadFileWithSignedUrl(file: File): Observable<string> {
        const fileName = this.getFileName(file);
        const filePath = `${Date.now()}_${fileName}`;

        return from(this.supabase.createSignedUploadUrl(this.bucketName, filePath)).pipe(
            switchMap(({ signedUrl, token, path }) => {
                console.log('***Signed URL Details***', { signedUrl, token, path });
                return from(this.supabase.uploadToSignedUrl(this.bucketName, path, token, file)).pipe(
                    map(() => path)
                );
            }),
            switchMap((path) => this.getSignedUrl(path)),
            catchError(error => {
                console.error('Error in file upload process:', error);
                return throwError(() => error);
            })
        );
    }

    getSignedUrl(path: string): Observable<string> {
        return from(this.supabase.createSignedUrl(this.bucketName, path, 60 * 60 * 24 * 7)).pipe(
            catchError(error => {
                console.error('Error getting signed URL:', error);
                return throwError(() => error);
            })
        );
    }


    getFileName(file: File | string): string {
        if (file instanceof File) {
            return file.name || `unnamed_file_${Date.now()}`;
        }
        const broadcastId = this.store.selectSnapshot(WhatsappSenderState.currentBroadcast);
        return `${broadcastId}_${Date.now()}`;
    }



    // claude
    // uploadFileWithSignedUrl(file: File): Observable<string> {
    //     const fileName = this.getFileName(file);
    //     const filePath = `${Date.now()}_${fileName}`;

    //     return from(this.supabase.createSignedUploadUrl(this.bucketName, filePath)).pipe(
    //         switchMap(({ signedUrl, token, path }) =>
    //             from(this.supabase.uploadToSignedUrl(this.bucketName, path, token, file)).pipe(
    //                 map(() => path)
    //             )
    //         ),
    //         switchMap((path) => this.getSignedUrl(path)),
    //         catchError(error => {
    //             console.error('Error in file upload process:', error);
    //             return throwError(() => error);
    //         })
    //     );
    // }

    // private getSignedUrl(path: string): Observable<string> {
    //     return from(this.supabase.createSignedUrl(this.bucketName, path, 60 * 60 * 24 * 7)).pipe(
    //         catchError(error => {
    //             console.error('Error getting signed URL:', error);
    //             return throwError(() => error);
    //         })
    //     );
    // }

    // private getFileName(file: File | string): string {
    //     if (file instanceof File) {
    //         return file.name || `unnamed_file_${Date.now()}`;
    //     }
    //     const broadcastId = this.store.selectSnapshot(WhatsappSenderState.broadcastId);
    //     return `${broadcastId}_${Date.now()}`;
    // }

    // uploadFileAndGetSignedUrl(file: File | string): Observable<string> {
    //     const fileName = this.getFileName(file);
    //     const filePath = `${Date.now()}_${fileName}`;

    //     const uploadOrGet = file instanceof File
    //         ? this.supabase.uploadFile(file, filePath, this.bucketName)
    //         : Promise.resolve(file);

    //     return from(uploadOrGet).pipe(
    //         switchMap(path => this.getSignedUrlWithRetry(path)),
    //         catchError(error => {
    //             console.error('Error uploading file or getting signed URL:', error);
    //             return throwError(() => error);
    //         })
    //     );
    // }



    // private getSignedUrlWithRetry(path: string): Observable<string> {
    //     return from(this.supabase.createSignedUrl(path, this.bucketName, 60 * 60 * 24 * 7)).pipe(
    //         retry({
    //             count: 5,
    //             delay: (error, retryCount) => {
    //                 console.log(`Retry attempt ${retryCount}`);
    //                 return timer(1000 * retryCount);
    //             }
    //         })
    //     );
    // }

    // getSignedUrl(path: string): Observable<string> {
    //     return from(this.supabase.createSignedUrl(path, this.bucketName, 60 * 60 * 24 * 7)).pipe(
    //         catchError(error => {
    //             console.error('Error getting signed URL:', error);
    //             return throwError(() => error);
    //         })
    //     );
    // }
}
