import { Component, AfterViewInit, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import Uppy from '@uppy/core';
import Dashboard from '@uppy/dashboard';
import Tus from '@uppy/tus';
import pt_BR from '@uppy/locales/lib/pt_BR';
import { environment } from 'apps/vertice/src/environments/environment';
import { FileUploadQuery } from '../file-upload.query';
import { SupabaseService } from 'apps/vertice/src/app/shared/services/supabase.service';

@Component({
  selector: 've-file-upload',
  templateUrl: './file-upload.component.html',

})
export class FileUploadComponent implements AfterViewInit {
  @Input() bucketName: string = 'broadcast-attachments';
  @Output() fileUploaded = new EventEmitter<{ fileName: string, fileUrl: string }>();
  @ViewChild('uppyDashboard') uppyDashboardElement!: ElementRef;

  private uppy!: Uppy;
  
  constructor(private fileUploadQuery: FileUploadQuery, private supabase: SupabaseService,) { }

  ngAfterViewInit() {
    this.initializeUppy();
  }

  initializeUppy() {
    const supabaseStorageURL = `${environment.supabaseUrl}/storage/v1/upload/resumable`;

    this.uppy = new Uppy({
      debug: true,
      autoProceed: false,
      restrictions: {
        maxFileSize: 10 * 1024 * 1024, // 10MB
        maxNumberOfFiles: 1,
        allowedFileTypes: ['image/*', 'video/*', 'audio/*', '.pdf', '.doc', '.docx']
      }
    })
      .use(Dashboard, {
        inline: true,
        target: this.uppyDashboardElement.nativeElement,
        height: 300,
        width: '100%',
        showProgressDetails: true,
        locale: pt_BR,
      })
      .use(Tus, {
        endpoint: supabaseStorageURL,
        headers: {
          'Authorization': `Bearer ${environment.supabaseServiceKey}`,
          'x-upsert': 'true'
        },
        chunkSize: 6 * 1024 * 1024,
        allowedMetaFields: ['bucketName', 'objectName', 'contentType', 'cacheControl']
      });

    this.uppy.on('file-added', (file) => {
      const supabaseMetadata = {
        bucketName: this.bucketName,
        objectName: file.name,
        contentType: file.type,
      };
      file.meta = {
        ...file.meta,
        ...supabaseMetadata,
      };
    });

    // v2
    this.uppy.on('upload-success', (file, response) => {
      if (file && file.name) {
        const publicUrl = this.supabase.getFilePublicUrl(this.bucketName, file.name);
        this.fileUploaded.emit({ fileName: file.name, fileUrl: publicUrl });
      }
    });
  }
  //   this.uppy.on('upload-success', (file, response) => {
  //     const uploadedUrl = `${environment.supabaseUrl}/storage/v1/object/public/${this.bucketName}/${file!.name}`;
  //     this.fileUploaded.emit({ fileName: file!.name, fileUrl: uploadedUrl });
  //   });
  // }
}