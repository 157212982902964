import { Component, EventEmitter, Input, Output } from '@angular/core';
// import { SelectDialogItem } from '@vertice/ui';
// import { TeamUserRole } from '@vertice/enums';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 've-select-item-role-dialog',
  templateUrl: './select-item-role-dialog.component.html',
})
export class SelectItemRoleDialogComponent {
  // @Input() item: SelectDialogItem | undefined;
  // @Input() selectedRole: TeamUserRole | undefined;
  @Output() selectionChanged = new EventEmitter<boolean>();

  // roles = [
  //   { value: TeamUserRole.TEAM_LEADER, viewValue: 'Team Leader' },
  //   { value: TeamUserRole.TEAM_MEMBER, viewValue: 'Team Member' }
  // ]

  // newRoleSelected(event: MatSelectChange) {
  //   if (this.item) {
  //     this.item.role = event.value
  //   }
  // }
}
