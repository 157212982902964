import { Component } from '@angular/core';

@Component({
  selector: 've-inline-success-box',
  templateUrl: './inline-success-box.component.html',
  styles: [
  ]
})
export class InlineSuccessBoxComponent {


}
