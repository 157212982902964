// router/router.actions.ts

import { Action } from '@ngxs/store';

export class GoToHome {
    static readonly type = '[Router] GoToHome';
}

export class GoTo404 {
    static readonly type = '[Router] GoTo404';
}

export class GoTo408 {
    static readonly type = '[Router] GoTo408';
}

export class Show408 {
    static readonly type = '[Error] Show408';
}

export class Show404 {
    static readonly type = '[Error] Show404';
}

export class Show500 {
    static readonly type = '[Error] Show500';
}

export class Show464 {
    static readonly type = '[Error] Show464';
}

export class GoToLogin {
    static readonly type = '[Router] GoToLogin';
    constructor(public readonly redirect?: string) { }
}

export class GoToContactLogin {
    static readonly type = '[Router] GoToContactLogin';
    constructor(public readonly redirect?: string) { }
}

export class GoTo {
    static readonly type = '[Router] GoTo';
    constructor(public readonly url: string) { }
}
