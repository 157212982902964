<div #uppyDashboard></div>
<!-- <div class="flex items-center justify-center w-10 h-10 font-normal text-white rounded-full" [ngClass]="backgroundColorClass ? backgroundColorClass : 'bg-secondary-400'"
     [matTooltip]="(firstName || '') + ' ' + (lastName || '')"
     matTooltipPosition="above" matTooltipShowDelay="500">
  <ng-container *ngIf="url; else initials">
    <img [src]="url" [alt]="(firstName | slice: 0:1) + ' ' + (lastName | slice: 0:1)" />
  </ng-container>
  <ng-template #initials>
          <span *ngIf="!!firstName">
            {{ firstName | slice: 0:1 }}{{ lastName | slice: 0:1 }}
          </span>
  </ng-template>
</div> -->
